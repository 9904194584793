/**
 * Fixes issue where we can't see query params in the app due to how vue-router's hash mode works
 * See https://github.com/vuejs/vue-router/issues/2125#issuecomment-519521424 for more details
 * This can be removed if we ever switch to history mode
 */

function getUrl(path) {
  const href = window.location.href;
  const hashPos = href.indexOf('#');
  let base = hashPos > -1 ? href.slice(0, hashPos) : href;

  const searchPos = base.indexOf('?');
  const query = searchPos > -1 ? base.slice(searchPos) : '';
  base = query ? base.slice(0, searchPos) : base;

  return `${base}#${path + query}`;
}

export const fixRoutes = () => {
  window.location.replace(getUrl(location.pathname));
};
