import axios from 'axios';
import store from '@/store';
import { logError } from '../helpers/errors';

export const getOrderByReturnKey = async (returnKey) => {
  const shopId = store.state.shopContents.id;
  return await axios.get(`api/v1/${shopId}/shoplater/${returnKey}`);
};

export const getRecommendationsForProduct = async (productId) => {
  const shopId = store.state.shopContents.id;
  try {
    const response = await axios.get(`api/v1/${shopId}/product-recommendations`, {
      params: { productId }
    });

    return response.data;
  } catch (error) {
    logError(error);

    return {
      errors: error.response.data
    };
  }
};
