<template>
  <component :is="layout">
    <error-module
      :status="error"
      :close-error-modal="closeErrorModal"
    />
    <router-view
      v-if="hasShop && initDataLoaded"
      :key="$route.path"
    />
    <portal-target name="modal" />
    <portal-target
      name="panels"
      multiple
    />
    <offline-message v-if="offline" />
    <toast-message
      :message="toast.message"
      :type="toast.type"
      @hidden="updateToast"
    />
    <component
      :is="devToolComponent"
      v-if="isDev"
    />
    <chat-widget v-if="hasChatWidget" />
  </component>
</template>

<script>
import { defineAsyncComponent, watch } from 'vue';
import { ToastMessage } from '@loophq/design-system';
import shopApi from './js/controllers/shop.js';
import trackingApi from './js/controllers/tracking.js';
import env from '@/env';
import useStoreMethods from '@/js/mixins/store';
const { setStore } = useStoreMethods();
import initData from '@/js/initData';
import { featureFlags } from '@/js/constants/featureFlags';
import { loadPoshmarkSDK } from '@/js/utility/resell/poshmark';
import DefaultLayout from '@/layouts/DefaultLayout';
import NavLayout from '@/layouts/NavLayout';
import GiftReceiptLayout from '@/layouts/GiftReceiptLayout';
import OfflineMessage from '@/components/OfflineMessage';
import ChatWidget from '@/components/ChatWidget';

export default {
  components: {
    DefaultLayout,
    NavLayout,
    GiftReceiptLayout,
    OfflineMessage,
    ToastMessage,
    ChatWidget,
  },
  data: function () {
    return {
      orders: {},
      initDataLoaded: false
    };
  },
  computed: {
    hasShop() {
      return !!this.$store.state.shopContents;
    },
    key() {
      if (this.$route.fullPath.startsWith('/shop')) {
        return 'shop';
      }
      return this.$route.fullPath;
    },
    error() {
      return this.$store.state.error;
    },
    layout() {
      return this.$route.meta.layout || 'DefaultLayout';
    },
    offline() {
      return !this.$store.state.online;
    },
    toast() {
      return this.$store.state.toast;
    },
    isDev() {
      return env('DEV') && env('VITE_MIRAGE_ENABLED') && !env('VITE_DISABLE_DEV_TOOL');
    },
    devToolComponent() {
      if (this.isDev) {
        return defineAsyncComponent(() => import(/* @vite-ignore */ './components/dev/DevTools.vue'));
      }
      return 'span';
    },
    hasChatWidget() {
      const chatWidgets = [
        'gorgias-chat',
        'zendesk-chat'
      ];
      return chatWidgets.some((widget => !!this.$settings.portalServicesConfigs?.[widget]));
    },
    poshmarkIsAvailable() {
      return this.$store.getters.hasFeature(featureFlags.LIST_WITH_POSHMARK);
    },
    poshmarkConfig() {
      return this.$store.getters['settings'].portalServicesConfigs?.poshmark ?? {};
    }
  },
  async created() {
    const fullUrl = window.location.href;
    if (fullUrl.includes('/tracking/')) {
      // If we are on a tracking page, we go through a separate flow to load initial data
      await this.initForTracking(fullUrl);
    } else {
      // Hit our api to get updated init data
      const res = await shopApi.getInitializationData();

      this.initDataLoaded = true;
      // if the domain or subdomain belongs to tracking, we route the user to the tracking lookup page
      if (res.is_tracking) {
        this.$router.push('/lookup');
      }

      // Save customizations to localstorage to make refreshes feel better
      setStore('init', res, '');
      // Rerun init with our updated data
      initData(this.$route, res);

      // if customer is hitting gift receipt routes but the gift receipt flow
      // is disabled, re-route them back to the main customer portal url.
      if (
        (this.$route.name === 'giftReceipt' || this.$route.name === 'gift-link') &&
        !this.$settings.enabledGiftReceiptFlow
      ) {
        window.location.href = this.$settings.customerPortalUrl;
      }
    }
  },
  mounted() {
    watch(
      () => [this.poshmarkIsAvailable, this.poshmarkConfig],
      ([isAvailable, config]) => {
        if (isAvailable && config?.sdk_source_uri) {
          loadPoshmarkSDK(config.sdk_source_uri);
        }
      },
      { immediate: true }
    );
  },
  methods: {
    updateToast() {
      this.$store.dispatch('toast', { message: '' });
    },
    closeErrorModal() {
      this.$store.commit('setError', null);
    },
    async initForTracking(fullUrl) {
      this.initDataLoaded = true;
      const trackingHash = fullUrl.split('/').pop();
      try {
        const trackingData = await trackingApi.loadTrackingData(trackingHash);
        this.$store.commit('tracking/setTrackingData', { ...trackingData, trackingHash });

        const shopProviderDomain = trackingData.trackerHashData?.shopProviderDomain ?? '';

        const res = await shopApi.getTrackingInitializationData(shopProviderDomain);

        setStore('init', res, '');
        initData(this.$route, res);
      } catch (err) {
        console.error(err);
        this.$router.push({ path: '/tracking-error' });
      }
    }
  }
};
</script>
