<template>
  <main class="app-container tracking-page">
    <div class="tracking-page__preview-banner">
      <back-to-shop
        class="back-to-shop-large"
      />
      <alert-banner
        v-if="previewMode"
        class="tracking-page__preview-alert"
        data-testid="tracking_page_preview_alert"
        :heading="previewAlertHeading"
        type="info"
        inline
        :description="previewAlertDescription"
      />
    </div>
    <app-header class="tracking-banner" />
    <base-card
      class="tracking-page__tracking-card tracking-page__delivery-timeline"
      data-testid="tracking_page_delivery_timeline"
    >
      <estimated-delivery-date
        :heading="heading"
        :body="estDeliveryDate"
        :tooltip="estDeliveryDateToolTipContent"
        heading-font-size="display-medium"
        class="tracking-page__delivery-date"
      />
      <event-timeline-steps
        class="tracking-page__event-timeline-steps"
        :order-name="trackingData.orderName"
        :tracker="trackingData.tracker"
        :shipping-status-health="currentShippingStatusHealth"
        :latest-shipping-status="latestShippingStatus"
        :fulfillment-tracking-url="trackingData.fulfillment?.trackingUrl"
      />
    </base-card>

    <base-card
      class="tracking-page__tracking-card tracking-page__tracking-updates"
      data-testid="tracking_page_tracking_updates"
    >
      <recent-updates
        :order-name="trackingData.orderName"
        :tracking-details="orderedTrackingDetails"
        :shipping-status-health="currentShippingStatusHealth"
        :top-level-status-detail="topLevelStatusDetail"
        :tracking-url="trackingUrl"
        :portal-return-url="portalReturnUrl"
        @customer-started-return="trackStartAReturn"
      />
    </base-card>
    <div
      v-if="hasShowProductDetailsFlag"
      class="tracking-page__product-information"
      data-test-id="tracking_product_information_section"
    >
      <product-information
        :current-shipping-status="latestShippingStatus.status"
        :portal-return-url="portalReturnUrl"
        :line-items="trackingData?.fulfillment?.lineItems"
        :order-name="trackingData?.orderName"
        @customer-started-return="trackStartAReturn"
      />
    </div>
    <base-card
      v-if="productRecommendationsEnabled"
      class="tracking-page__tracking-card tracking-page__product-recommendations"
    >
      <product-recommendations :recommendations-list="recommendationsList" />
    </base-card>
  </main>
</template>

<script setup>
import EstimatedDeliveryDate from '@/views/ForwardTracking/EstimatedDeliveryDate.vue';
import { AlertBanner } from '@loophq/design-system';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { formatTrackingDate } from '@/js/helpers/formatDate';
import EventTimelineSteps from '@/views/ForwardTracking/EventTimelineSteps.vue';
import RecentUpdates from '@/views/ForwardTracking/RecentUpdates.vue';
import { toSentenceCase } from '@/js/helpers/formatTextCase';
import { useRoute } from 'vue-router';
import { getPortalReturnUrl } from '@/js/helpers/urls';
import { trackEvent } from '@/js/helpers/trackEvent';
import ProductInformation from '@/views/ForwardTracking/ProductInformation.vue';
import { featureFlags } from '@/js/constants/featureFlags';
import { labelStatuses } from '@/js/constants/labels';
import { trackingStatusHealth } from '@/js/constants/tracking';
import ProductRecommendations from '@/views/ProductRecommendations.vue';
import tracking from '@/js/controllers/tracking';
import { track, identify } from '@/js/segment';
import { orderTrackingEvents, products } from '@/js/constants/segment';
import BackToShop from '@/components/app/BackToShop.vue';

const store = useStore();
const route = useRoute();

trackEvent('page visits by shop');

const previewMode = computed(() => {
  return route.query?.preview === 'true';
});

const heading = store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateHeading;

const previewAlertHeading = store.getters['content']?.moduleForwardTracking.previewAlertHeading;

const previewAlertDescription = store.getters['content']?.moduleForwardTracking.previewAlertDescription;

const trackingData = computed(() => {
  return store.getters['tracking/tracking'];
});

const recommendationsListFromService = ref([]);

const recommendationsList = computed(() => {
  let recommendationsData;
  const recommendationsProductsCount = store.getters['tracking/recommendationsProductsCount'];
  const recommendationsListTemplate = store.state.tracking.tracking.recommendationsList ?? [];

  recommendationsData = recommendationsListTemplate;

  if ((recommendationsData.length === 0 || recommendationsData[0] === undefined)
    && store.getters['tracking/tracking']?.recommendationsEnabled
  ) {

    recommendationsData = recommendationsListFromService.value;
    recommendationsData = recommendationsData?.recommendations ?? [];
  }

  if (recommendationsProductsCount !== null) {

    recommendationsData = Array(recommendationsProductsCount).fill(recommendationsListTemplate[0]);
  }

  // This mapping will not be necessary once the recommendation engine has been updated to return data in the mapped format.
  return recommendationsData.map((item) => ({
    id: item.id,
    title: item.title,
    link: item.link,
    tags: item.tags,
    image: {
      alt: item.image?.alt,
      src: item.image?.src,
    },
    variant: item.variants[0]?.title,
    price: item.variants[0]?.price?.replace('.', '')
  }));
});

const hasShowProductDetailsFlag = computed(() => {
  return store.getters.hasFeature(featureFlags.ORDER_TRACKING_PRODUCT_DETAILS);
});

const currentShippingStatusHealth = computed(() => {
  return store.getters['tracking/currentShippingStatusHealth'];
});

const topLevelStatusDetail = computed(() => {
  return toSentenceCase(store.state.tracking.tracking?.tracker?.status_detail);
});

const orderedTrackingDetails = computed(() => {
  const trackingDetailsBase = store.state.tracking.tracking?.tracker?.tracking_details;
  return trackingDetailsBase?.reverse();
});

const trackingUrl = computed(() => {
  return store.state.tracking.tracking?.tracker?.public_url ?? '';
});

const latestShippingStatus = computed(() => {
  return store.getters['tracking/latestShippingStatus'];
});

const portalReturnUrl = computed(() => {
  const shopDomain = store.state.tracking.tracking?.trackerHashData?.shopDomain || '';
  const isReturnable = store.state.tracking.tracking?.fulfillment?.isReturnable === 'true' || store.state.tracking.tracking?.fulfillment?.isReturnable === true;
  if (store.getters['tracking/latestShippingStatus'].status === labelStatuses.DELIVERED && store.state.tracking.tracking?.shippingAddressZip && store.state.tracking.tracking?.orderName && isReturnable) {
    return getPortalReturnUrl(shopDomain, store.state.tracking.tracking.orderName, store.state.tracking.tracking?.shippingAddressZip);
  }
  return null;
});

const estDeliveryDate = computed(() => {
  if (store.state.tracking.tracking?.tracker?.est_delivery_date) {
    return formatTrackingDate(store.state.tracking.tracking.tracker?.est_delivery_date);
  }

  if (store.getters['tracking/latestShippingStatus'].status === labelStatuses.DELIVERED) {
    return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateDelivered;
  }

  return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateComingSoon;
});

// This order was picked so the default message will be “We've received your order and the delivery date will be provided as soon as possible.”
const estDeliveryDateToolTipContent = computed(() => {
  if (store.getters['tracking/latestShippingStatus'].status === labelStatuses.DELIVERED) {
    return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateTooltipDelivered;
  }

  if (store.state.tracking.tracking?.tracker?.est_delivery_date && store.getters['tracking/currentShippingStatusHealth'] === trackingStatusHealth.OK) {
    return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateTooltipOk;
  }

  if (!store.state.tracking.tracking?.tracker?.est_delivery_date && [trackingStatusHealth.WARNING, trackingStatusHealth.ERROR].includes(store.getters['tracking/currentShippingStatusHealth'])) {
    return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateTooltipNoDateWarnError;
  }

  return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateTooltipNoDateOk;
});

const productRecommendationsEnabled = computed(() => {
  return store.getters.hasFeature(featureFlags.ORDER_TRACKING_RECOMMENDATIONS)
    && recommendationsList.value.length !== 0
    && store.getters.settings.orderTrackingProductRecommendationsEnabled;
});

const loadProductRecommendations = async () => {
  try {
    recommendationsListFromService.value = await tracking.loadTrackingRecommendations({
      shopId: store.getters.shop.id,
      trackingHash: store.state.tracking.tracking.trackingHash,
      providerProductIds: store.getters['tracking/productIds'],
      providerCustomerId: store.state.tracking.tracking.providerCustomerId,
      customerEmail: store.state.tracking.tracking.customerEmail
    });
  } catch (error) {
    console.error('Error occurred loading product recommendations', error);
  }
};

const analyticsData = computed(() => {
  return store.getters['analytics/getData'];
});

const trackStartAReturn = () => {
  track(orderTrackingEvents.START_RETURN_CLICKED, {
    ...analyticsData.value
  });
};

onMounted(async () => {
  if (
    store.getters.hasFeature(featureFlags.ORDER_TRACKING_RECOMMENDATIONS)
    && store.getters['tracking/tracking']?.recommendationsEnabled
    && store.getters['tracking/tracking'].recommendationsList === undefined
  ) {
    await loadProductRecommendations();
  }

  const product = products.ORDER_TRACKING;
  identify(store.state.tracking.tracking?.providerCustomerId, { product });

  const analytics = {
    customerIdentifier: store.state.tracking.tracking?.providerCustomerId,
    session: store.state.tracking.tracking?.session,
    order: store.state.tracking.tracking?.orderProviderId,
    shop: store.getters.shop.id,
    shopName: store.getters.shop.name,
    product,
  };

  store.commit('analytics/setData', { ...analytics });

  let recommendationsProductIdList = null;

  if (store.getters.settings.orderTrackingProductRecommendationsEnabled) {
    recommendationsProductIdList = recommendationsList.value?.map(recommendation => recommendation?.id);
  }

  track(orderTrackingEvents.TRACKING_PAGE_VISIT, {
    ...analytics,
    status: latestShippingStatus.value?.status || 'unknown',
    reccEngine: store.getters.settings.orderTrackingProductRecommendationsEngine,
    reccSource: store.getters.settings.orderTrackingProductRecommendationsDataSource,
    reccProducts: recommendationsProductIdList
  });
});

</script>

<style lang="scss" scoped>
.app-container {
  margin-top: 0;
  overflow-x: hidden;
}

$block: '.tracking-page';

#{$block} {
  padding-bottom: var(--spacing-400);

  &__tracking-card {
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    align-items: stretch;
    margin: auto;
    max-width: 980px;
    border-radius: var(--corners);
    border: 1px solid #d8dbdf;
  }

  &__preview-banner {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-300);
  }

  &__preview-alert {
    max-width: 690px;
  }

  &__delivery-timeline,
  &__tracking-updates {
    margin-bottom: var(--spacing-400);
  }

  &__tracking-updates {
    padding: 2.5rem;
  }

  &__product-recommendations {
    padding: 2.5rem;
    max-width: none;
    border: hidden;
    border-radius: unset;
    background-color: #f3f3f3;
    margin: var(--spacing-600) 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .base-text {
    width: 100%;
  }

  &__delivery-date {
    flex-shrink: 0;
    display: flex;
    padding: 2.5rem;
  }

  &__event-timeline-steps {
    flex-grow: 1;
    padding: 2.5rem 0;
  }

  &__shrink-event-timeline {
    max-width: 640px;
  }
}

@media screen and (width <= 1402px) {
  #{$block} {
    justify-content: center;
    text-align: center;

    &__delivery-date {
      width: 100%;
      order: 1;
      padding: var(--spacing-400);
      border-top: 1px solid var(--grey-200);
      border-right: none;
    }
  }
}

@media screen and (width <= 680px) {
  #{$block} {
    &__tracking-card,
    &__product-information {
      margin-left: var(--spacing-300);
      margin-right: var(--spacing-300);
    }

    &__product-recommendations {
      padding: 0;
      margin: var(--spacing-300) 0;
    }

    &__preview-banner {
      margin-top: 0;
    }
  }

  .back-to-shop-large {
    display: none;
  }
}

:deep(.display-xlarge) {
  font-family: var(--heading-font);
}

.back-to-shop-large.back-to-shop-large {
  position: fixed;
  left: var(--spacing-500);
  top: var(--spacing-500);
}
</style>
