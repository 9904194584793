import methods from '@/js/constants/firstMile';
import { HappyReturnsDenseZipCodes } from '@/js/constants/HappyReturnsDenseZipCodes';

export const reorderEligibility = (options, addressZip) => {
  const singleReturnMethod = options.find(({ name }) => methods.SINGLE_RETURN_METHODS.includes(name));

  if (singleReturnMethod) {
    // Display as the only option instead of the normal return method selector
    return [singleReturnMethod];
  }

  // This is contractually defined with Happy Returns. If the customer lives in a densely populated zipcode (HR defined)
  // then the distance we prefer HR over other methods is 2 miles, meaning if they live in that radius we list HR first
  // If they are not in a dense zip code we prefer HR at a radius of 10 miles of a location.
  // Do not change this radius without knowing the contracted values
  const happyReturnsPreferredRadius = HappyReturnsDenseZipCodes.includes(addressZip) ? methods.DENSE_POPULATION_MILES : methods.REGULAR_POPULATION_MILES;
  let orderedOptions = [];
  let loopPos = null;
  let omitBoxAndShip = false;
  // Set the order we want to display return_method options
  options.forEach(option => {
    if (option.name === methods.HAPPY_RETURNS && option.distance?.value <= happyReturnsPreferredRadius) {
      orderedOptions.unshift(option);
    } else if (option.name === methods.LOOP_POS) {
      loopPos = option;
    } else {
      option.name !== methods.SHOPIFY_POS && orderedOptions.push(option); 
    }

    // If any of the options are Rebound carriers, we don't need to display the Box and Ship method
    if (methods.RETURN_METHODS_THAT_REPLACE_BOX_AND_SHIP.includes(option.name)) {
      omitBoxAndShip = true;
    }
  });

  // Regardless of the other return options we always want POS to be the first option shown.
  if (loopPos) {
    orderedOptions.unshift(loopPos);
  }
  
  if (omitBoxAndShip) {
    orderedOptions.pop();
  }
  return orderedOptions;
};

/**
 * Polling logic
 * 
 * @param {function} fetch - callback to be called every interval
 * @param {number} interval - interval at which calls are made, defaults to 5 sec
 * @param {number} maxRetry - max number of retries, defaults to 36 times
 */
export const refetch = async ({ fetch, handleTimeout, interval = 5000, maxRetry = 3 }) => {
  let retries = 0;
  const timer = setInterval(async () => {
    if (++retries >= maxRetry) {
      clearInterval(timer);
      handleTimeout('timed-out');
    }
    await fetch(timer);
  }, interval);
};
