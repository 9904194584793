<template>
  <div class="olive-home-pickup__content">
    <base-button
      class="olive-home-pickup__button"
      type="primary"
      target="_blank"
      @click="handleScheduleButton"
    >
      <span class="olive-home-pickup__button-label">
        <render-content>
          {{ $content.moduleOliveHomePickup.button }}
        </render-content>
      </span>
    </base-button>
  </div>
</template>

<script>
import { BaseButton } from '@loophq/design-system';

export default {
  components: {
    BaseButton
  },
  props: {
    returnMethod: {
      type: Object,
      required: true,
    },
    orderName: {
      type: String,
      required: true,
    }
  },
  methods: {
    handleScheduleButton() {
      if (this.returnMethod.scheduledAt) {
        window.open(this.returnMethod.meta.schedulePickupUrl.replace('/return-shipper/schedule-pickup', ''));
      } else {
        window.open(this.returnMethod.meta.schedulePickupUrl + `?externalReturnId=${this.returnMethod.returnId}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.olive-home-pickup';

#{$block} {
  &__button {
    max-width: 280px;
    width: 100%;
    font-size: 0.9375rem;
  }
}
</style>
