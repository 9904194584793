<template>
  <main class="app-container">
    <div class="app-wrap">
      <app-header />

      <div class="app-banner">
        <h1 v-if="slideActive === 'slide1'">
          <render-content>
            {{ $content.pageCredit.heading1 }}
          </render-content>
        </h1>
        <h1 v-else-if="slideActive === 'slide2'">
          <render-content>
            {{ $content.pageCredit.heading2 }}
          </render-content>
        </h1>
      </div>

      <div
        class="credit-container view"
        :class="{
          slide1: slideActive === 'slide1',
          slide2: slideActive === 'slide2',
          large: shopCashEnabled,
        }"
      >
        <div
          v-if="!hideStorefront"
          class="credit-view first"
        >
          <span
            v-if="slideActive === 'slide2'"
            class="credit-cover"
            aria-hidden="true"
            @click="previousSlide"
          ></span>
          <transition
            name="card"
            appear
          >
            <shop-now-preview
              class="shop-preview"
              :products="preview.products"
              :additional-products="preview.count"
              :loading="preview.loading"
              :credit-amount="totals.credit.bonus.total"
              :refund-amount="refundAmount"
              :shop-now-amount="shopNowAmount"
              :outcomes-enabled="outcomesEnabled"
              :handling-fee="handlingFee || order.handling_fee"
              :rules="ruleCheck"
              :only-exchanges="onlyExchanges"
              @refund="handleRefund"
              @shop="toStore"
            />
          </transition>
        </div>

        <div
          class="credit-view narrow second"
          :class="{ single: hideStorefront, 'narrow-shop-cash': shopCashEnabled }"
        >
          <component
            :is="creditOptionComponent"
            v-if="showRegularStoreCredit"
            :id="options.giftCard.id"
            :data="options.giftCard"
            :totals="totals"
            :loading-status="currentlyLoading"
            :option="options.giftCard.id"
            :validation="actionValidation(options.refund.id)"
            :class="{ deactivated: flowCheck.noGiftCard || ruleCheck.banGift }"
            :rules="ruleCheck"
            :flow-check="flowCheck"
            :amount="options.giftCard.amount"
            data-dd-action-name="Store Credit"
          />
          <component
            :is="creditOptionComponent"
            v-else-if="showShopCashStoreCredit"
            :id="options.giftCardWithShopCash.id"
            :data="options.giftCardWithShopCash"
            :totals="totals"
            :loading-status="currentlyLoading"
            :option="options.giftCardWithShopCash.id"
            :fee="order.handling_fee"
            :flow-check="flowCheck"
            :validation="actionValidation(options.giftCardWithShopCash.id)"
            :class="{ deactivated: flowCheck.noGiftCard || ruleCheck.banGift }"
            :rules="ruleCheck"
            v-bind="options.giftCardWithShopCash"
            data-dd-action-name="Gift Card Shop Cash"
            @toggle-more-information="toggleMoreInformation"
          />
          <component
            :is="creditOptionComponent"
            v-if="order.return_policy.refunds_enabled"
            :id="options.creditCard.id"
            :data="options.creditCard"
            :totals="totals"
            :loading-status="currentlyLoading"
            :option="options.creditCard.id"
            :fee="order.handling_fee"
            :flow-check="flowCheck"
            :validation="actionValidation(options.refund.id)"
            :class="{ deactivated: flowCheck.exchangeOnly || ruleCheck.banRefund }"
            :rules="ruleCheck"
            :amount="options.creditCard.amount"
            data-dd-action-name="Original Payment Method"
          />
        </div>
      </div>

      <p
        v-if="
          flowCheck.exchangeOnly &&
            slideActive === 'slide2' &&
            !(ruleCheck.banGift && ruleCheck.banRefund)
        "
        class="credit-extra small"
      >
        <render-content>
          {{ $content.pageCredit.exchangeOnly }}
        </render-content>
      </p>
      <p
        v-if="ruleCheck.banGift && ruleCheck.banRefund"
        class="credit-extra small"
      >
        <render-content>
          {{ $content.pageCredit.noOutcome }}
        </render-content>
      </p>

      <div
        :class="{
          'credit-actions-shop-cash': shopCashEnabled,
          'credit-actions': !shopCashEnabled,
          'pt-500': slideActive === 'slide1' && shopCashEnabled,
        }"
      >
        <transition
          name="fade"
          appear
        >
          <base-button
            v-if="shopCashEnabled && slideActive === 'slide2'"
            class="continue-button"
            type="primary"
            :disabled="!returnCreditType"
            @click.prevent="handleContinue"
          >
            {{ $content.pageCredit.continue }}
          </base-button>
        </transition>
        <div>
          <base-button
            v-if="hasPreviousSlide"
            :class="shopCashEnabled ? 'go-back-button' : null"
            :type="shopCashEnabled ? 'text' : 'secondary'"
            @click.prevent="slideActive = 'slide1'"
          >
            <render-content>
              {{
                shopCashEnabled ? $content.pageCredit.back : $content.pageCredit.goBack
              }}
            </render-content>
          </base-button>
          <base-button
            v-else
            type="secondary"
            to="/exchange"
          >
            <render-content>
              {{ $content.pageCredit.goBack }}
            </render-content>
          </base-button>
        </div>
      </div>

      <app-footer :page="creditPage" />
    </div>
    <shop-cash-info-panel
      :has-shop-cash-explanation="showShopCashExplanation"
      :bonus-amount="totals.credit.bonus.platformCredit.addOnBonusAmount"
      @handle-info-toggle="toggleMoreInformation"
    />
  </main>
</template>

<script>
import { BaseButton, BasePanel } from '@loophq/design-system';
import ShopNowPreview from './Credit/ShopNowPreview';
import { getFirstCollection, mapNavigation } from './Shop/shoputil';
import { generateOnstoreUrl } from '@/js/onstore';
import warrantiesMixin from '@/js/mixins/modules/warranties';
import { featureFlags } from '@/js/constants/featureFlags';
import { returnCreditTypes, storeCreditTypes } from '@/js/constants/returns';
import CreditOptionNew from '@/components/globals/CreditOption.New.vue';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';
import { track } from '@/js/segment';
import ShopCashInfoPanel from '@/components/panels/ShopCashInfoPanel.vue';
import { customerPortalPageNames } from '@/js/constants/segment';

const getStoreName = (name) => {
  return `${name.charAt(0).toUpperCase()}${name.substr(1)}`;
};

export default {
  name: 'CreditPage',
  components: {
    BaseButton,
    ShopNowPreview,
    CreditOptionNew,
    BasePanel,
    MarkupRenderer,
    ShopCashInfoPanel,
  },
  mixins: [warrantiesMixin, ShopCashInfoPanel],
  data() {
    return {
      slideActive: 'slide1',
      hideStorefront: false,
      currentlyLoading: '',
      showShopCashExplanation: false,
      creditPage: customerPortalPageNames.CREDIT_PAGE
    };
  },
  computed: {
    order() {
      return this.$store.getters.order;
    },
    showRegularStoreCredit() {
      return (
        (this.order.return_policy.gift_cards_enabled &&
          !this.storeCreditWithShopCashAvailable) ||
        (this.warrantyAllowedOutcomes.gift && !this.storeCreditWithShopCashAvailable)
      );
    },
    showShopCashStoreCredit() {
      return (
        (this.order.return_policy.gift_cards_enabled &&
          this.storeCreditWithShopCashAvailable) ||
        (this.warrantyAllowedOutcomes.gift && this.storeCreditWithShopCashAvailable)
      );
    },
    shopCashEnabled() {
      const hasShopCashFF = this.$store.getters.hasFeature(featureFlags.SHOP_CASH);
      const hasPlatformCredit = this.totals.credit?.platformCreditAvailable;
      const hasAddOnBonus = this.totals.credit?.bonus.platformCredit.addOnBonusAmount > 0;
      const newReceiptItemsEmpty = !Object.keys(this.receipt.newItems ?? {}).length > 0;
      return hasShopCashFF && hasPlatformCredit && hasAddOnBonus && newReceiptItemsEmpty;
    },
    storeCreditWithShopCashAvailable() {
      return (
        this.shopCashEnabled && !!this.totals.credit.bonus.platformCredit.addOnBonusAmount
      );
    },
    totals() {
      return this.$store.state.totals.totals;
    },
    receipt() {
      return this.$store.state.totals.receipt;
    },
    options() {
      return {
        SWC: {
          title: this.$content.pageCredit.shopNowHeading,
          copy: this.$content.pageCredit.shopNowCopy
            ? this.$content.pageCredit.shopNowCopy
            : `Shop ${getStoreName(this.$shop.name)} instantly with your return value.`,
          action: this.toStore,
          showBonus: true,
          id: 'SWC',
        },
        refund: {
          title: this.$content.pageCredit.noThanksHeading,
          copy: this.$content.pageCredit.noThanksCopy,
          action: this.nextSlide,
          showFee: this.refundFee?.amount > 0,
          handlingFee: this.refundFee,
          id: returnCreditTypes.credit,
        },
        giftCard: {
          title: this.$content.pageCredit.storeCreditHeading,
          copy: this.$content.pageCredit.storeCreditCopy,
          action: this.toReview,
          showFee: this.creditFee?.amount > 0,
          handlingFee: this.creditFee,
          id: returnCreditTypes.GIFT,
          base: this.isItemInCart
            ? this.receipt.total
            : this.totals.credit.withPartialReturnCredit,
          amount: this.isItemInCart
            ? this.cartCreditAmount
            : this.totals.credit.preCalculated.storeCredit,
          showBonus: true,
          bonusAmount: this.totals.credit.bonus.storeCreditBonus ?? 0,
          active: this.isCreditActive(returnCreditTypes.GIFT, storeCreditTypes.GIFTCARD),
        },
        creditCard: {
          title: this.shopCashEnabled
            ? this.$content.pageCredit.standardRefundHeading
            : this.$content.pageCredit.ogPaymentHeading,
          copy: this.shopCashEnabled
            ? this.$content.pageCredit.standardRefundCopy
            : this.$content.pageCredit.ogPaymentCopy,
          showFee: this.refundFee?.amount > 0,
          handlingFee: this.refundFee,
          action: this.toReview,
          id: returnCreditTypes.REFUND,
          amount: this.isItemInCart
            ? this.receipt.total
            : this.totals.credit.preCalculated.refundCredit,
          active: this.isCreditActive(
            returnCreditTypes.REFUND,
            storeCreditTypes.GIFTCARD
          ),
        },
        shopCash: {
          title: this.$content.pageCredit.shopCashHeading,
          copy: this.$content.pageCredit.shopCashCopy,
          showFee: this.creditFee?.amount > 0,
          handlingFee: this.refundFee,
          action: this.toReview,
          id: returnCreditTypes.SHOPCASH,
          amount: this.totals.credit.preCalculated.platformCredit.creditAmount,
          active: this.isCreditActive(returnCreditTypes.GIFT, storeCreditTypes.SHOPCASH),
        },
        giftCardWithShopCash: {
          title: this.$content.pageCredit.storeCreditShopCashBonusHeading,
          copy: this.$content.pageCredit.shopCashCopy,
          action: this.toReview,
          showFee: this.creditFee?.amount > 0,
          handlingFee: this.creditFee,
          id: storeCreditTypes.GIFT_CARD_WITH_SHOP_CASH,
          base: this.isItemInCart
            ? this.receipt.total
            : this.totals.credit.withPartialReturnCredit,
          amount: this.totals.credit.preCalculated.storeCredit,
          showBonus: true,
          bonusAmount: this.totals.credit.bonus.platformCredit.addOnBonusAmount,
          active: this.isCreditActive(returnCreditTypes.GIFT, storeCreditTypes.SHOPCASH),
        },
      };
    },
    ruleCheck: function () {
      const bans = {
        banExchange: false,
        banRefund: false,
        banGift: false,
        banShopNow: false,
      };

      Object.values(this.order.line_items).forEach((item) => {
        if (item.returnType !== undefined) {
          if (item.returnType === 'credit') {
            if (!item.allowed.refund || item.excluded?.refund) {
              bans.banRefund = true;
            }
            if (!item.allowed.gift || item.excluded?.storeCredit) {
              bans.banGift = true;
            }
            if (!item.allowed.exchange) {
              bans.banExchange = true;
            }

            if (!item.allowed.shopNow || item.excluded?.shopNow) {
              bans.banShopNow = true;
            }

            if (
              item.rules?.applied.length > 0 &&
              this.$settings.shopNowDisabledWhenRuleApplies
            ) {
              bans.banShopNow = true;
            }

            if (this.$store.getters.wasCashPurchased) {
              bans.banRefund = true;
            }
          }
        }
      });

      return bans;
    },
    onlyExchanges: function () {
      return !Object.values(this.order.line_items).some((item) => {
        return item.returnType !== undefined && item.returnType !== 'exchange';
      });
    },

    flowCheck: function () {
      const flows = {
        noFee: false,
        exchangeOnly: false,
        noGiftCard: false,
        noRefund: false,
      };

      // eslint-disable-next-line
      Object.entries(this.order.line_items).forEach(([, item]) => {
        if (item.returnType !== undefined) {
          if (item.returnType === 'exchange') {
            flows.noFee = true;
          }
          if (
            (item.returnType === 'credit' && item.allowed.refund === false) ||
            this.order.enabled.refund === 'no'
          ) {
            flows.exchangeOnly = true;
          }
        }
      });

      if (this.order.cart !== undefined) {
        if (this.order.cart.length > 0) {
          flows.noFee = true;
        }
      }
      if (this.order.enabled.gift === 'no' && !this.warrantyAllowedOutcomes?.gift) {
        flows.noGiftCard = true;
      }

      return flows;
    },
    onStoreActive: function () {
      return this.order.enabled.on_store_api;
    },
    handlingFee() {
      return this.$store.getters['fees/handlingFee'];
    },
    creditFee() {
      return this.handlingFee?.fees.credit;
    },
    refundFee() {
      return this.handlingFee?.fees.refund;
    },
    hasPreviousSlide() {
      const hasStorefront = this.order.enabled.storefront === 'yes';
      const hideChoices = this.ruleCheck.banShopNow || this.ruleCheck.banExchange;
      return this.slideActive === 'slide2' && hasStorefront && !hideChoices;
    },
    productType() {
      const lineItems = Object.values(this.order.line_items);
      const lineItem = lineItems.find((item) => !!item.returnType);

      return lineItem?.product_type;
    },
    preview() {
      const empty = {
        loading: true,
        products: [],
        count: null,
      };

      return this.$store.state.collections.previews[this.productType] ?? empty;
    },
    outcomesEnabled() {
      // Return the enabled object with values cast to booleans
      return Object.entries(this.order.enabled).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: value === 'yes',
        };
      }, {});
    },
    isReimbursementsEnabled() {
      return this.$settings.reimbursements;
    },
    isItemInCart() {
      return this.$store.state.order.cart?.length > 0;
    },
    cartCreditAmount() {
      return this.receipt.total + this.totals.credit.bonus.storeCreditBonus;
    },
    refundAmount() {
      if (this.isItemInCart) {
        return this.receipt.total;
      }
      if (
        !this.isReimbursementsEnabled &&
        this.totals.credit.withPartialReturnCredit !== 0
      ) {
        return this.totals.credit.withPartialReturnCredit;
      }
      const { refundCredit, storeCredit } = this.totals.credit.preCalculated;
      return Math.max(refundCredit, storeCredit);
    },
    shopNowAmount() {
      if (this.isItemInCart) {
        return this.receipt.total + this.totals.credit.bonus.total;
      }
      return this.totals.credit.withPartialReturnCredit + this.totals.credit.bonus.total;
    },
    creditOptionComponent() {
      return this.shopCashEnabled ? 'credit-option-new' : 'credit-option';
    },
    returnCreditType() {
      return this.$store.state.return.creditType;
    },
    storeCreditType() {
      return this.$store.state.return.storeCreditType;
    },
    analytics() {
      return this.$store.getters['analytics/getData'];
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
    if (this.ruleCheck.banShopNow) {
      if (this.$store.getters['return/isSplitReturn']) {
        this.$router.push('/review');
      }

      this.hideStorefront = true;
      this.slideActive = 'slide2';
    }
    track('availablePresentedOptions', {
      ...this.analytics,
      exchange: this.outcomesEnabled.exchange,
      shopNow: {
        onStoreApi: this.outcomesEnabled.on_store_api,
        storeFront: this.outcomesEnabled.storefront,
      },
      refund: this.outcomesEnabled.refund,
      storeCredit: this.outcomesEnabled.gift,
      storeCreditBonusShopCash: this.outcomesEnabled.gift && this.shopCashEnabled,
      storeCreditFullShopCash: false, // for now this is always false
    });
  },
  async created() {
    const shopNowForAll = this.order.return_policy.shop_now_for_all_enabled;

    if (!shopNowForAll && this.order.enabled.storefront === 'no') {
      this.hideStorefront = true;
      this.slideActive = 'slide2';
    } else {
      // Order has shop now, preload collections
      this.$store.dispatch('getTabs').then((collections) => {
        // Preload first collection if available
        if (collections.length) {
          const active = getFirstCollection(collections);
          this.$store.commit('shopnow/updateNav', mapNavigation(collections, active));
          this.$store.commit('shopnow/setProperty', { key: 'preloading', value: true });
          this.$store.dispatch('shopnow/getCollection', {
            collection: active,
            payload: { order_id: this.order.id },
          });
        }
      });
      this.$store.dispatch('collections/getPreview', { productType: this.productType });
    }

    // If shop now is deactivated, send to refund screen immediately
    if (this.ruleCheck.banShopNow) {
      this.hideStorefront = true;
      this.slideActive = 'slide2';
    }

    if (!this.order) {
      this.$router.push('/');
    }
  },
  methods: {
    actionValidation: function (type) {
      let isDisabled = false;
      if (this.order.eligibility[type] === false || this.order.enabled[type] === 'no') {
        isDisabled = true;
      }

      Object.values(this.order.line_items).forEach((item) => {
        if (item.returnType === 'credit') {
          if (item.allowed[type] === false) {
            isDisabled = true;
          }
        }
      });

      return isDisabled;
    },

    //Set type and navigate to confirm
    async toReview(creditType) {
      // If in edit mode, we can ignore the actual totals calls
      if (this.$store.state.edits.active) {
        this.$router.push({ name: 'review' });
        return;
      }

      const updatedOrder = this.order;

      if (this.flowCheck.exchangeOnly && creditType === 'refund') {
        return false;
      }
      if (this.flowCheck.noGiftCard && creditType === 'gift') {
        return false;
      }

      this.currentlyLoading = creditType;

      this.$trackEvent(`credit type selected: ${creditType}`);

      if (creditType === returnCreditTypes.GIFT) {
        this.$store.commit('return/setStoreCreditType', storeCreditTypes.GIFTCARD);
      }
      if (creditType === storeCreditTypes.GIFT_CARD_WITH_SHOP_CASH) {
        /*
         * Shop cash is an extension of gift cards. We need the credit type to be 'gift'
         * we are setting the store credit type to differentiate between issuing a gift-card vs shop-cash
         */
        creditType = 'gift';
        this.$store.commit(
          'return/setStoreCreditType',
          storeCreditTypes.GIFT_CARD_WITH_SHOP_CASH
        );
      }

      updatedOrder['creditType'] = creditType;

      this.$store.commit('updateOrder', updatedOrder);
      this.$store.commit('return/setCreditType', creditType);

      const { errors } = await this.$store.dispatch('totals/get');
      if (errors) {
        this.$store.commit('setError', {
          copy: errors,
        });
      }
      if (!this.showShopCashStoreCredit || this.$store.getters['return/isSplitReturn']) {
        this.$router.push({ name: 'review' });
      }
    },

    // Navigate to Shop Now
    toStore() {
      this.$trackEvent('shop with credit selected');

      if (this.onStoreActive === 'yes') {
        window.location.href = generateOnstoreUrl('credit');
      } else {
        this.$router.push({ name: 'shop' });
      }
    },

    nextSlide() {
      this.slideActive = 'slide2';
    },
    previousSlide() {
      if (this.slideActive === 'slide2') {
        this.slideActive = 'slide1';
      }
    },
    handleRefund() {
      if (this.outcomesEnabled.refund && !this.outcomesEnabled.gift) {
        this.toReview('refund');
        return false;
      }

      if (this.outcomesEnabled.gift && !this.outcomesEnabled.refund) {
        this.toReview('gift');
        return false;
      }

      if (this.refundAmount <= 0) {
        this.toReview('exchange');
        return false;
      }

      if (this.$store.getters['return/isSplitReturn']) {
        this.toReview('gift');
        return false;
      }

      this.nextSlide();
    },
    handleContinue() {
      this.$router.push({ name: 'review' });
    },
    toggleMoreInformation() {
      this.showShopCashExplanation = !this.showShopCashExplanation;
    },
    isCreditActive(creditType, storeCreditType) {
      return (
        this.returnCreditType === creditType && this.storeCreditType === storeCreditType
      );
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: left !important;
}

.credit-container {
  position: relative;
  width: 100%;
  max-width: 48rem;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: var(--theme-corners);
  min-height: 280px;

  &.slide1 {
    .first {
      width: 100%;
      transform: translateX(0);
      z-index: 1;
    }

    .second {
      top: 50%;
      left: 50%;
      transform: translate3d(-20%, -50%, 0);
      opacity: 0;
      z-index: 0;
      position: absolute;
    }
  }

  &.slide2 {
    &.large {
      max-height: 300px;
    }

    .first {
      transform: translate3d(-100%, 0, 0);
      opacity: 0.3;

      &.narrow {
        transform: translate3d(-110%, 0, 0);
      }
    }

    .second:not(.single) {
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 0;
      position: absolute;
      opacity: 1;
      transition: all 0.3s 0.15s;
    }
  }
}

.credit-cover {
  content: '';
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.shop-preview {
  width: 100%;
  max-width: 48rem;
}

.credit-extra {
  text-align: center;
  max-width: 360px;
  padding-top: var(--spacing-500);
  margin: auto;
  animation: fadeIn 0.3s forwards;
  color: var(--theme-text-color);
}

.credit-view {
  flex-shrink: 0;
  width: 100%;
  top: 0;
  transition: all 0.4s;

  &.narrow {
    max-width: 480px;
  }

  &.narrow-shop-cash {
    max-width: 354px;
  }
}

.continue-button {
  width: 354px;

  &:disabled:not(.activated) {
    background-color: rgba(237, 238, 241, 100%) !important;
  }
}

.credit-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--spacing-600);
  padding-bottom: var(--spacing-300);
  gap: var(--spacing-300);
}

.credit-actions-shop-cash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--spacing-200);
  padding-bottom: var(--spacing-100);
  gap: var(--spacing-300);
}

:deep(.go-back-button) {
  background: none !important;
}

@media screen and (width <= 900px) {
  .credit-view {
    left: 0;
  }
}

@media screen and (width <= 680px) {
  .credit-view {
    transition: opacity var(--easing-300);
  }

  .credit-container {
    padding: 1rem;
    min-height: 220px;

    &.slide1 {
      padding: 0;

      .first {
        width: 100%;
        transform: translateX(0);
        z-index: 1;
      }

      .second {
        top: 0;
        left: 0;
        transform: translate3d(0, 0, 0);
        opacity: 0;
        z-index: 0;
        position: absolute;
      }
    }

    &.slide2 {
      padding: 0;

      .first {
        position: absolute;
        transform: translate3d(0, 0, 0);
        opacity: 0;
        z-index: 0;
      }

      .second:not(.single) {
        top: 0;
        left: 0;
        transform: translate3d(0, 0, 0);
        z-index: 1;
        position: relative;
        transition: opacity var(--easing-300);
        opacity: 1;
      }
    }
  }
}
</style>
