<template>
  <accordion-card
    toggle-placement="right"
    :heading="`${labelCardHeader} ${ count }`"
    class="label-card"
  >
    <div
      class="label-card__return-code"
      data-testid="qr-code-button"
    >
      <slot :current-label="label"></slot>

      <div>
        <base-text type="heading">
          {{ label.code }}
        </base-text>
      </div>

      <line-item-list
        :items="label.lineItems"
        :content="content"
        :is-qr-code="isQrCode"
      />
    </div>
  </accordion-card>
</template>

<script setup>
import { BaseText } from '@loophq/design-system';
import AccordionCard from '@/views/StatusPage/AccordionCard';
import LineItemList from './LineItemList.vue';
import { computed } from 'vue';

const props = defineProps({
  label: Object,
  count: Number,
  returnId: Number,
  shopId: Number,
  content: Object,
  isQrCode: Boolean,
});

const labelCardHeader = computed(() => {
  return props.content.cardHeaderIndicator;
});
</script>

<style lang="scss" scoped>
.label-card {
  :deep(.base-accordion) {
    justify-content: space-between;
  }

  &__return-code {
    @media screen and (max-width: $break-small) {
      padding: var(--spacing-400) var(--spacing-500);
    }
  }

  :deep(.base-accordion__toggle) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  :deep(.print-label__button) {
    width: 100%;
    background-color: var(--button-background-color);
    color: var(--button-text-color);
  }

  :deep(.base-button__icon) {
    --icon-color: var(--grey-900) !important;
  }

  :deep(.label-card__button-content) {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  :deep(.label-card__button-copy) {
    color: var(--button-text-color);
    margin-left: var(--spacing-300);
  }
}
</style>
