import { Response } from 'miragejs';
import { orders } from './mocks/orders';
import { returns } from './mocks/returns';

const BoxAndShipPayload = {
  'name': 'pre-paid',
  'logo': '/img/integrations/first-mile/ship.svg',
  'fees': {
    'fees': {
      'credit': {
        'amount': 100,
        'setByWorkflows': false,
      },
      'exchange': {
        'amount': 100,
        'setByWorkflows': false,
      },
      'refund': {
        'amount': 100,
        'setByWorkflows': false,
      }
    }
  }
};

const happyReturnsPayload = {
  'type': 'drop-off',
  'name': 'happy-returns',
  'closest': [
    {
      'address': {
        'id': 3174,
        'name': 'FedEx Office',
        'address1': '604 W Schrock Rd',
        'address2': '',
        'city': 'Columbus',
        'state': 'OH',
        'zip': '43081',
        'country': 'United States of America',
        'country_code': 'US',
        'phone': '',
        'latitude': 39.995435,
        'longitude': -83.02732630000001
      },
      'location': {
        'id': 2907,
        'name': 'CC0451',
        'accepts_returns': true,
        'active': true,
        'legacy': false,
        'fulfillable': true,
        'nexus': false,
        'source': 'happy-returns',
        'provider_id': 9000
      },
      'distance': {
        'value': 0.5,
        'unit': 'm'
      },
      'display': [
        {
          'label': 'Directions',
          'value': 'Go to the Ship Center to show your QR code.'
        },
        {
          'label': 'Hours',
          'value': [
            {
              'id': 74480,
              'dayOfWeek': 1,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74481,
              'dayOfWeek': 2,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74482,
              'dayOfWeek': 3,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74483,
              'dayOfWeek': 4,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74484,
              'dayOfWeek': 5,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74485,
              'dayOfWeek': 6,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T09:00:00-04:00'
            },
            {
              'id': 74486,
              'dayOfWeek': 7,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T18:00:00-04:00',
              'openingTime': '2023-10-23T11:00:00-04:00'
            }
          ]
        },
        [
          {
            'label': 'Promotions',
            'value': '$10 off your qualifying Staples purchase of $30 or more.'
          }
        ]
      ]
    },
    {
      'address': {
        'id': 3174,
        'name': 'Cost Plus World Market - North Montgomeryville',
        'address1': '3858 Morse Rd',
        'address2': 'Clinton Township',
        'city': 'Columbus',
        'state': 'OH',
        'zip': '43219',
        'country': 'United States of America',
        'country_code': 'US',
        'phone': '',
        'latitude': 39.995435,
        'longitude': -83.02732630000001
      },
      'location': {
        'id': 2907,
        'name': 'CC0451',
        'accepts_returns': true,
        'active': true,
        'legacy': false,
        'fulfillable': true,
        'nexus': false,
        'source': 'happy-returns',
        'provider_id': 9000
      },
      'distance': {
        'value': 1.200,
        'unit': 'm'
      },
      'display': [
        {
          'label': 'Directions',
          'value': 'Go to the Ship Center to show your QR code.'
        },
        {
          'label': 'Hours',
          'value': [
            {
              'id': 74480,
              'dayOfWeek': 1,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74481,
              'dayOfWeek': 2,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74482,
              'dayOfWeek': 3,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74483,
              'dayOfWeek': 4,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74484,
              'dayOfWeek': 5,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74485,
              'dayOfWeek': 6,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T09:00:00-04:00'
            },
            {
              'id': 74486,
              'dayOfWeek': 7,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T18:00:00-04:00',
              'openingTime': '2023-10-23T11:00:00-04:00'
            }
          ]
        },
        [
          {
            'label': 'Promotions',
            'value': '$10 off your qualifying Staples purchase of $30 or more.'
          }
        ]
      ]
    },
    {
      'address': {
        'id': 3174,
        'name': 'Giant Eagle',
        'address1': '650 North State Street',
        'address2': '',
        'city': 'Westerville',
        'state': 'OH',
        'zip': '43082',
        'country': 'United States of America',
        'country_code': 'US',
        'phone': '',
        'latitude': 39.995435,
        'longitude': -83.02732630000001
      },
      'location': {
        'id': 2907,
        'name': 'CC0451',
        'accepts_returns': true,
        'active': true,
        'legacy': false,
        'fulfillable': true,
        'nexus': false,
        'source': 'happy-returns',
        'provider_id': 9000
      },
      'distance': {
        'value': 3.1,
        'unit': 'm'
      },
      'display': [
        {
          'label': 'Directions',
          'value': 'Go to the Ship Center to show your QR code.'
        },
        {
          'label': 'Hours',
          'value': [
            {
              'id': 74480,
              'dayOfWeek': 1,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74481,
              'dayOfWeek': 2,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74482,
              'dayOfWeek': 3,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74483,
              'dayOfWeek': 4,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74484,
              'dayOfWeek': 5,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T08:00:00-04:00'
            },
            {
              'id': 74485,
              'dayOfWeek': 6,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T21:00:00-04:00',
              'openingTime': '2023-10-23T09:00:00-04:00'
            },
            {
              'id': 74486,
              'dayOfWeek': 7,
              'locationID': 'CC0451',
              'closingTime': '2023-10-23T18:00:00-04:00',
              'openingTime': '2023-10-23T11:00:00-04:00'
            }
          ]
        },
        [
          {
            'label': 'Promotions',
            'value': '$10 off your qualifying Staples purchase of $30 or more.'
          }
        ]
      ]
    },
  ],
  'address': {
    'id': 1897,
    'type': 'location',
    'name': 'Cost Plus World Market - Montgomeryville',
    'address1': '751 Horsham Rd',
    'address2': 'Unit B2',
    'city': 'Lansdale',
    'state': 'PA',
    'country': 'United States',
    'country_code': 'US',
    'zip': '19446',
    'latitude': 40.2472656,
    'longitude': -75.2397963
  },
  'location': {
    'id': 1,
    'name': 'Cost Plus World Market - Montgomeryville',
    'accepts_returns': true,
    'active': true,
    'legacy': false,
    'fulfillable': true,
    'nexus': false,
    'source': 'happy-returns',
    'provider_id': 1
  },
  'directions': 'Returns are accepted at the "Buy online pick up in store" register.',
  'distance': {
    'value': 1234,
    'unit': 'm',
  },
  'display': [
    {
      'label': 'Hours',
      'value': 'Sun 12pm - 5pm, Mon - Sat 11am - 7pm'
    },
    {
      'label': 'Promotions',
      'value': '10% off your next in-store purchase when you return to this location.'
    },
    {
      'label': 'Notes',
      'value': 'Masks are required at this store'
    },
  ],
  'configuration': [],
  'logo': '/img/integrations/first-mile/happy-returns.svg',
  'fees': {
    'fees': {
      'credit': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'exchange': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'refund': {
        'amount': 500,
        'setByWorkflows': true,
      }
    }
  }
};



const loopPosPayload = {
  'type': 'drop-off',
  'name': 'loop-pos',
  'state': 'pre_transit',
  'address': {
    'id': 1897,
    'type': 'location',
    'name': 'Cost Plus World Market - Montgomeryville',
    'address1': '751 Horsham Rd',
    'address2': 'Unit B2',
    'city': 'Lansdale',
    'state': 'PA',
    'country': 'USA',
    'country_code': 'US',
    'zip': '19446',
    'latitude': 40.2472656,
    'longitude': -75.2397963
  },
  'qr_code_url': 'https://api.krypton.loopdev.rocks/api/v1/qr/i/379_1ByMqCBsP78vRVzKrUg7jE.png',
  'location': {
    'id': 1,
    'name': 'Cost Plus World Market - Montgomeryville',
    'nexus': false,
    'active': true,
    'legacy': false,
    'source': 'shopify',
    'fulfillable': false,
    'provider_id': 1,
    'accepts_returns': true
  },
  'configuration': [],
  'display': [],
  'distance': {
    'value': 1,
    'unit': 'm',
  },
};

const loopPosAndHappyReturnsPayload = [
  {
    'name': 'happy-returns',
    'type': 'drop-off',
    'logo': '/img/integrations/first-mile/happy-returns.svg',
    'configuration': [],
    'address': {
      'id': 44,
      'name': 'FedEx Office 0153',
      'address1': '180 N High St',
      'address2': '',
      'city': 'Columbus',
      'state': 'OH',
      'zip': '43215',
      'country': 'United States of America',
      'country_code': 'US',
      'phone': '',
      'latitude': 39.9659063,
      'longitude': -83.0009691
    },
    'location': {
      'id': 7,
      'name': 'fxo0153',
      'accepts_returns': true,
      'active': true,
      'legacy': false,
      'fulfillable': true,
      'nexus': false,
      'source': 'happy-returns',
      'provider_id': 4144
    },
    'distance': {
      'value': 0.643,
      'unit': 'm'
    },
    'display': [
      {
        'label': 'Directions',
        'value': 'Go to the shipping counter and show your QR code.'
      },
      {
        'label': 'Hours',
        'value': 'Sun 12 PM - 6 PM, Mon - Fri 8 AM - 8 PM, Sat 9 AM - 6 PM, Hours may vary during the holidays'
      }
    ],
    'fees': {
      'fees': {
        'credit': {
          'amount': 400,
          'setByWorkflows': true,
        },
        'exchange': {
          'amount': 400,
          'setByWorkflows': true,
        },
        'refund': {
          'amount': 500,
          'setByWorkflows': true,
        }
      }
    }
  },
  {
    'name': 'loop-pos',
    'type': 'drop-off',
    'configuration': [],
    'address': {
      'id': 3867,
      'name': 'Reynoldsburg Branch',
      'address1': '970 South Waggoner Road',
      'address2': '',
      'city': 'Reynoldsburg',
      'state': 'Ohio',
      'zip': '43068',
      'country': 'United States of America',
      'country_code': 'US',
      'phone': '',
      'latitude': 39.964345,
      'longitude': -82.796547
    },
    'location': {
      'id': 3812,
      'name': 'Reynoldsburg Branch',
      'accepts_returns': true,
      'active': true,
      'legacy': false,
      'fulfillable': false,
      'nexus': false,
      'source': 'shopify',
      'provider_id': 60937535621
    },
    'distance': {
      'value': 10.94,
      'unit': 'm'
    },
    'display': [],
    'fees': {
      'fees': {
        'credit': {
          'amount': 400,
          'setByWorkflows': true,
        },
        'exchange': {
          'amount': 400,
          'setByWorkflows': true,
        },
        'refund': {
          'amount': 500,
          'setByWorkflows': true,
        }
      }
    }
  }
];

const OlivePayload = {
  'type': 'pick-up',
  'name': 'olive',
  'logo': '/img/integrations/first-mile/olive.png',
  'fees': {
    'fees': {
      'credit': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'exchange': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'refund': {
        'amount': 500,
        'setByWorkflows': true,
      }
    }
  }
};

const VehoPayload = {
  'id': 24,
  'return_id': 37,
  'address_id': 4208,
  'type': 'pick-up',
  'name': 'veho',
  'logo': '/img/integrations/first-mile/veho.svg',
  'qr_code_url': null,
  'rma_code': null,
  'state': 'new',
  'meta': {
    'veho': {
      'instructions': 'Leave at door: Make sure you\'re not seen',
      'phone_number': '6148675309',
      'service_date': '2022-09-06',
      'pickup_address': {
        'zip': '08002',
        'city': 'Cherry Hill',
        'state': 'New Jersey',
        'address1': '123 Street Street',
        'address2': ''
      }
    }
  },
  'scheduled_at': '2022-09-02 17:38:13',
  'expires_at': null,
  'created_at': '2022-09-02 17:37:56',
  'updated_at': '2022-09-02 17:38:13',
  'deleted_at': null,
  'address': {
    'id': 4208,
    'hash': '880704af0b55f73ad27ed545b8e026f8',
    'created_at': '2022-09-02 15:51:37',
    'updated_at': '2022-09-02 15:51:37',
    'name': 'Shirt Buyerson',
    'company': null,
    'address1': '123 Street Street',
    'address2': '',
    'city': 'Cherry Hill',
    'state': 'New Jersey',
    'zip': '08002',
    'country': 'United States',
    'country_code': 'US',
    'phone': null,
    'latitude': 39.9311729,
    'longitude': -75.0260667
  },
  'fees': {
    'fees': {
      'credit': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'exchange': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'refund': {
        'amount': 500,
        'setByWorkflows': true,
      }
    }
  }
};

const InpostPayload = {
  'id': 24,
  'return_id': 37,
  'address_id': 4208,
  'type': 'drop-off',
  'name': 'inpost',
  'logo': '/img/integrations/first-mile/inpost.svg',
  'qr_code_url': 'https://partner.happyreturns.com/barcode/qr?code=HRSB83YQ',
  'rma_code': null,
  'state': 'new',
  'scheduled_at': '2022-09-02 17:38:13',
  'expires_at': null,
  'created_at': '2022-09-02 17:37:56',
  'updated_at': '2022-09-02 17:38:13',
  'deleted_at': null,
  'address': {
    'id': 4208,
    'hash': '880704af0b55f73ad27ed545b8e026f8',
    'created_at': '2022-09-02 15:51:37',
    'updated_at': '2022-09-02 15:51:37',
    'name': 'Shirt Buyerson',
    'company': null,
    'address1': '123 Street Street',
    'address2': '',
    'city': 'Cherry Hill',
    'state': 'New Jersey',
    'zip': '08002',
    'country': 'United States',
    'country_code': 'US',
    'phone': null,
    'latitude': 39.9311729,
    'longitude': -75.0260667
  },
  'fees': {
    'fees': {
      'credit': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'exchange': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'refund': {
        'amount': 500,
        'setByWorkflows': true,
      }
    }
  }
};

const AsdaPayload = {
  'id': 24,
  'return_id': 37,
  'address_id': 4208,
  'type': 'drop-off',
  'name': 'asda',
  'logo': '/img/integrations/first-mile/asda.svg',
  'qr_code_url': 'https://partner.happyreturns.com/barcode/qr?code=HRSB83YQ',
  'rma_code': null,
  'state': 'new',
  'scheduled_at': '2022-09-02 17:38:13',
  'expires_at': null,
  'created_at': '2022-09-02 17:37:56',
  'updated_at': '2022-09-02 17:38:13',
  'deleted_at': null,
  'address': {
    'id': 4208,
    'hash': '880704af0b55f73ad27ed545b8e026f8',
    'created_at': '2022-09-02 15:51:37',
    'updated_at': '2022-09-02 15:51:37',
    'name': 'Shirt Buyerson',
    'company': null,
    'address1': '123 Street Street',
    'address2': '',
    'city': 'Cherry Hill',
    'state': 'New Jersey',
    'zip': '08002',
    'country': 'United States',
    'country_code': 'US',
    'phone': null,
    'latitude': 39.9311729,
    'longitude': -75.0260667
  },
  'fees': {
    'fees': {
      'credit': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'exchange': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'refund': {
        'amount': 500,
        'setByWorkflows': true,
      }
    }
  }
};

const EvriPayload = {
  'id': 24,
  'return_id': 37,
  'address_id': 4208,
  'type': 'drop-off',
  'name': 'evri',
  'logo': '/img/integrations/first-mile/evri.svg',
  'options': [
    'evri-drop-off-qr-code',
    'evri-drop-off-label'
  ],
  'qr_code_url': 'https://partner.happyreturns.com/barcode/qr?code=HRSB83YQ',
  'rma_code': null,
  'state': 'new',
  'scheduled_at': '2022-09-02 17:38:13',
  'expires_at': null,
  'created_at': '2022-09-02 17:37:56',
  'updated_at': '2022-09-02 17:38:13',
  'deleted_at': null,
  'address': {
    'id': 4208,
    'hash': '880704af0b55f73ad27ed545b8e026f8',
    'created_at': '2022-09-02 15:51:37',
    'updated_at': '2022-09-02 15:51:37',
    'name': 'Shirt Buyerson',
    'company': null,
    'address1': '123 Street Street',
    'address2': '',
    'city': 'Cherry Hill',
    'state': 'New Jersey',
    'zip': '08002',
    'country': 'United States',
    'country_code': 'US',
    'phone': null,
    'latitude': 39.9311729,
    'longitude': -75.0260667
  },
  'fees': {
    'fees': {
      'credit': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'exchange': {
        'amount': 400,
        'setByWorkflows': true,
      },
      'refund': {
        'amount': 500,
        'setByWorkflows': true,
      }
    }
  }
};

const SekoUspsPayload = {
  'name': 'seko',
  'type': 'drop-off',
  'logo': '/img/integrations/first-mile/ship.svg',
  'options': [
    'qr',
    'label'
  ],
};

const routes = (context) => {
  // Intercept and delay order 1800 by 3 seconds to test loading screen
  context.post('/:shopId/first-mile/1800/eligibility', () => {
    return new Response(
      200,
      { 'Content-Type': 'application/json' },
      [BoxAndShipPayload]
    );
  }, { timing: 2000 });

  context.post('/:shopId/first-mile/:orderId/eligibility', (schema, request) => {
    let payload = [];

    let order = orders.find(o => Number(request.params.orderId) === o.id);

    switch (order?.name) {
      case 'loop pos return eligible':
        payload.push(loopPosPayload);
        break;
      case 'happy returns eligible':
        payload.push(happyReturnsPayload);
        break;
      case 'loop pos and happy returns eligible':
        payload = loopPosAndHappyReturnsPayload;
        break;
      case 'olive pickup eligible':
        payload.push(OlivePayload);
        break;
      case 'veho pickup eligible':
        payload.push(VehoPayload);
        break;
      case 'rebound eligible':
        payload.push(InpostPayload, AsdaPayload, EvriPayload);
        break;
      case 'seko usps eligible':
        payload.push(SekoUspsPayload);
        break;
      case 'delayed eligibility response':
        break;
    }

    payload.push(BoxAndShipPayload);

    return new Response(
      200,
      { 'Content-Type': 'application/json' },
      payload
    );
  });

  context.get('/:shopId/return/:returnKey/return-method', (schema, request) => {
    let payload = '';
    const r = returns.find(r => request.params.returnKey === r.return_key);
    switch (r?.return_key) {
      case 'happyreturn':
        payload = happyReturnsPayload;
        payload.qr_code_url = 'https://easypost-files.s3-us-west-2.amazonaws.com/files/form/20201027/0a134b52ad144ac88f58af9fcde1ee59.png';
        payload.returnId = 1;
        break;
      case 'loop-pos':
        payload = loopPosPayload;
        break;
      case 'olive':
        payload = OlivePayload;
        break;
      case 'veho':
      case 'veholabelfail':
        payload = VehoPayload;
        break;
      case 'vehoschedulefail':
        payload = VehoPayload;
        payload.scheduled_at = null;
        break;
      case 'inpost':
        payload = InpostPayload;
        break;
      case 'asda':
        payload = AsdaPayload;
        break;
      case 'evri':
        payload = EvriPayload;
        break;
    }

    return new Response(
      payload === null ? 204 : 200,
      { 'Content-Type': 'application/json' },
      payload
    );
  });
};

export default {
  routes
};
