import { merge } from 'lodash';
import { createDestination } from './destinations';
import { locations } from './locations';
import { flatFee } from './fees';

const returnPolicy = {
  'id': 17,
  'title': 'Domestic',
  'default': true,
  'is_catch_all': false,
  'labels_enabled': true,
  'exchanges_enabled': true,
  'exchange_event': 'in_transit',
  'exchange_window': 90,
  'refunds_enabled': true,
  'refund_event': 'in_transit',
  'refund_window': 90,
  'gift_cards_enabled': true,
  'gift_card_event': 'in_transit',
  'gift_card_window': 90,
  'advanced_exchanges_enabled': true,
  'shop_now_enabled': true,
  'shop_now_for_all_enabled': false,
  'on_store_enabled': false,
  'shop_now_bonus': 2299,
  'handling_fee': 1000,
  'keep_item_enabled': false,
  'keep_item_immediate': false,
  'keep_item_unique': false,
  'keep_item_threshold': 9900,
  'instant_return_window': 30,
  'instant_return_enabled': false,
  'instant_return_authorization_type': 'single_unit_amount',
  'customizations': [
    {
      'lang': 'en',
      'content': {
        'pageConfirmation': {
          'instructions': [
            'This is the domestic policy.',
            'Send us back stuff.'
          ]
        }
      }
    }
  ],
  'reimbursement': null,
  'fees': [
    {
      ...flatFee,
      is_active: false,
    }
  ]
};

/**
 * Creates an order object
 * @param {string} id - The id to assign to this line item
 * @returns {object} A line item object that matches the BE endpoint
 */
export const createOrder = (name, lineItems = [], keys = {}) => {

  return {
    'provider_order_id': null,
    'name': name,
    'line_item_ids': lineItems,
    'customer': {
      'id': 81,
      'hasEmail': true,
      'hasAddress': true,
    },
    'address': {
      'id': 5306,
    },
    'return_policy': returnPolicy,
    'fulfilled_at': {
      'date': '2020-06-16 20:55:35.000000',
      'timezone_type': 3,
      'timezone': 'UTC'
    },
    'eligibility': {
      'refund': true,
      'gift': true
    },
    'eligible': '2020-09-14',
    'return_window_active': true,
    'prepaid_labels': [],
    'enabled': {
      'exchange': 'yes',
      'refund': 'yes',
      'gift': 'yes',
      'storefront': 'yes',
      'on_store_api': 'no'
    },
    'upsell_method': 'on-store',
    'minimum_inventory': '5',
    'handling_fee': 0,
    'stripe': false,
    'currency': 'USD',
    'refund_total': 0,
    'total': 7744,
    'tax_total': 544,
    'subtotal': 8000,
    'discounts_total': 800,
    'taxes_included': false,
    'diff_priced_exchanges': 'yes',
    'shipping_total': 0,
    'attempts': 0,
    'multi_currency': false,
    'original_presentment_currency': 'USD',
    'original_presentment_exchange_rate': 1,
    'stores': null,
    'portal_services_configs': {},
    'workflows': [],
    ...keys
  };
};

export const orders = [
  createOrder(
    'regular order',
    [1, 2, 3, 4, 5],
    {
      'minimum_inventory': '4',
      'handling_fee': 1000,
      'stripe': true,
      'total': 63708,
      'tax_total': 4447,
      'subtotal': 59261,
      'discounts_total': 0,
    }
  ),
  createOrder(
    'discounted order',
    [
      [11, { discount: 200 }],
      [12, { discount: 500 }],
      [13, { discount: 1000 }],
      [14, { discount: 2000 }]
    ]
  ),
  createOrder(
    'no customer',
    [15],
    {
      'customer': {
        'id': 0,
        'created_at': '2020-07-13 18:27:34',
        'updated_at': '2020-07-13 18:27:34',
        'first_name': '',
        'middle_name': null,
        'last_name': '',
        'email': '',
        'shop_id': 0
      }
    }
  ),
  {
    'name': 'order not found',
    'errors': ['errorCode1']
  },
  createOrder(
    'consistent order',
    [
      [16, {
        product_id: 1,
        allowed: {
          shopNow: true,
          gift: true,
          refund: true,
          exchange: true,
        }
      }],
      [17, {
        product_id: 1,
        allowed: {
          exchange: true,
          gift: true,
          reason: 'ineligibleCode4',
          refund: true,
          return: true,
          returned: true,
          shopNow: true,
        }
      }],
    ],
    { cart: [] },
    {
      'return_policy': merge(
        {},
        returnPolicy,
        {
          'instant_return_enabled': true
        }
      )
    }
  ),
  createOrder(
    'order with multiple destinations',
    [
      [23, {
        product_id: 1,
        destinations: [createDestination(1, {
          name: 'Displayed destination',
          display_name_in_portal: true
        })]
      }],
      [24, {
        product_id: 1,
        destinations: [createDestination(1, {
          name: 'Displayed destination',
          display_name_in_portal: true
        })]
      }],
      [25, {
        product_id: 1,
        destinations: [createDestination(2, {
          name: 'Alternate destination',
          display_name_in_portal: true
        })]
      }],
      [26, {
        product_id: 1,
        destinations: [createDestination(2, {
          name: 'Alternate destination',
          display_name_in_portal: true
        })]
      }],
    ],
    { cart: [] }
  ),
  createOrder(
    'order with bundle',
    [
      [27, {
        is_bundle: 1,
        bundle: {
          name: 'My fake bundle',
          partial_returns: true
        }
      }],
      [28, {
        bundle_oli_id: 27
      }],
      [29, {
        bundle_oli_id: 27
      }],
      [30, {
        bundle_oli_id: 27,
        'allowed': {
          'return': true,
          'refund': true,
          'exchange': true,
          'gift': true,
          'returned': true,
          'reason': '',
          'shopNow': true,
        }
      }],
    ]
  ),
  createOrder(
    'out of stock',
    [
      [32, { product_id: 9 }],
      [33, { product_id: 10 }],
    ]
  ),
  createOrder(
    'no shop now',
    [
      [36, { product_id: 1 }],
    ],
    {
      'enabled': {
        'exchange': 'yes',
        'refund': 'yes',
        'gift': 'yes',
        'storefront': 'no',
        'on_store_api': 'no'
      }
    }
  ),
  createOrder(
    'advanced exchanges',
    [
      [37, {
        'product_id': 1,
        'advanced_exchange': {
          'id': 0,
          'shop_id': 0,
          'search_string': 'Advanced',
          'operator': 'contains',
          'enabled': true,
          'title_visible': false,
          'title': 'Advanced exchange',
          'options': [
            {
              'id': 0,
              'advanced_exchange_id': 0,
              'collection_id': 1,
              'order': 0,
              'copy': {
                'en': {
                  'name': 'Make advanced exchange'
                },
                'fr': {
                  'name': 'Faire un échange avancé'
                }
              }
            }
          ],
          'variant_option': true,
          'return_option': true
        }
      }],
      [38, {
        'product_id': 3,
        'advanced_exchange': {
          'id': 1,
          'shop_id': 0,
          'search_string': 'Advanced 2',
          'operator': 'contains',
          'enabled': true,
          'title_visible': true,
          'title': 'Advanced exchange',
          'options': [
            {
              'id': 0,
              'advanced_exchange_id': 0,
              'collection_id': 1,
              'order': 0,
              'copy': {
                'en': {
                  'name': 'Advanced exchange with title'
                },
                'fr': {
                  'name': 'Échange avancé avec titre'
                }
              }
            }
          ],
          'variant_option': true,
          'return_option': true
        }
      }],
      [39, {
        'product_id': 12,
        'advanced_exchange': {
          'id': 1,
          'shop_id': 0,
          'search_string': 'Advanced 3',
          'operator': 'contains',
          'enabled': true,
          'title_visible': false,
          'title': 'Advanced exchange',
          'options': [
            {
              'id': 0,
              'advanced_exchange_id': 0,
              'collection_id': 10,
              'order': 0,
              'copy': {
                'en': {
                  'name': 'Advanced exchange with large collection'
                },
                'fr': {
                  'name': 'Échange avancé avec une grande collection'
                }
              }
            }
          ],
          'variant_option': true,
          'return_option': true
        }
      }]
    ]
  ),
  createOrder(
    'product has hidden variants',
    [
      [40, { product_id: 11 }],
    ],
  ),
  createOrder(
    'product has no description',
    [
      [41, { description: null, product_id: 1 }]
    ]
  ),
  createOrder(
    'varied product options',
    [
      [42, { product_id: 2 }],
      [43, { product_id: 3 }],
      [44, { product_id: 4 }],
    ],
    { cart: [] }
  ),
  createOrder(
    'exchange only',
    [
      [45, {
        product_id: 1,
        allowed: {
          exchange: true,
          gift: false,
          reason: 'exchangeOnly',
          refund: false,
          return: false,
          returned: false,
          shopNow: true,
        }
      }],
    ],
    {
      'enabled': {
        'exchange': 'yes',
        'refund': 'no',
        'gift': 'no',
        'storefront': 'no',
        'on_store_api': 'no'
      },
      cart: []
    }
  ),
  createOrder(
    'instant return - exchange',
    [
      [45, { product_id: 1 }],
    ],
    {
      'enabled': {
        'exchange': 'yes',
        'refund': 'yes',
        'gift': 'yes',
        'storefront': 'no',
        'on_store_api': 'no'
      }
    },
    {
      'return_policy': merge(
        {},
        returnPolicy,
        {
          'instant_return_enabled': true
        }
      )
    }
  ),
  createOrder(
    'store credit only',
    [
      [46, { product_id: 1 }],
    ],
    {
      'enabled': {
        'exchange': 'no',
        'refund': 'no',
        'gift': 'yes',
        'storefront': 'no',
        'on_store_api': 'no'
      }
    }
  ),
  createOrder(
    'refund only',
    [
      [47, { product_id: 1 }],
    ],
    {
      'enabled': {
        'exchange': 'no',
        'refund': 'yes',
        'gift': 'no',
        'storefront': 'no',
        'on_store_api': 'no'
      }
    }
  ),
  createOrder(
    'store credit and shop now only',
    [
      [48, {
        product_id: 1,
        allowed: {
          refund: false,
          gift: true,
          shopNow: true,
          exchange: false,
          return: true,
        }
      }],
    ],
    {
      'enabled': {
        'exchange': 'no',
        'refund': 'no',
        'gift': 'yes',
        'storefront': 'yes',
        'on_store_api': 'no'
      },
      cart: []
    }
  ),
  createOrder(
    'refund and shop now only',
    [
      [49, {
        product_id: 1,
        allowed: {
          refund: true,
          gift: false,
          shopNow: true,
          exchange: false,
          return: true,
        }
      }],
    ],
    {
      'enabled': {
        'exchange': 'no',
        'refund': 'yes',
        'gift': 'no',
        'storefront': 'yes',
        'on_store_api': 'no'
      },
      cart: []
    }
  ),
  createOrder(
    'refund, exchange and shop now',
    [
      [49, {
        product_id: 1,
        allowed: {
          refund: true,
          gift: false,
          shopNow: true,
          exchange: false,
          return: true,
        }
      }],
    ],
    {
      'enabled': {
        'exchange': 'yes',
        'refund': 'yes',
        'gift': 'no',
        'storefront': 'yes',
        'on_store_api': 'no'
      },
      cart: []
    }
  ),
  createOrder(
    'store credit, refund, and shop now',
    [
      [49, {
        product_id: 1,
        allowed: {
          refund: true,
          gift: true,
          shopNow: true,
          exchange: false,
          return: true,
        }
      }],
    ],
    {
      'enabled': {
        'exchange': 'no',
        'refund': 'yes',
        'gift': 'yes',
        'storefront': 'yes',
        'on_store_api': 'no'
      },
      cart: []
    }
  ),
  createOrder(
    'store credit + shop cash and refund, no exchanges',
    [
      [49, {
        product_id: 1,
        allowed: {
          refund: true,
          gift: true,
          shopNow: false,
          exchange: false,
          return: true,
        }
      }],
    ],
    {
      'enabled': {
        'exchange': 'no',
        'refund': 'yes',
        'gift': 'yes',
        'storefront': 'no',
        'on_store_api': 'no'
      },
      cart: []
    }
  ),
  createOrder(
    'exchange and shop now only',
    [
      [50, { product_id: 1 }],
    ],
    {
      'enabled': {
        'exchange': 'yes',
        'refund': 'no',
        'gift': 'no',
        'storefront': 'yes',
        'on_store_api': 'no'
      },
      cart: []
    }
  ),
  createOrder(
    'exchange, store credit and shop now',
    [
      [50, { product_id: 1 }],
    ],
    {
      'enabled': {
        'exchange': 'yes',
        'refund': 'no',
        'gift': 'yes',
        'storefront': 'yes',
        'on_store_api': 'no'
      },
      cart: []
    }
  ),
  createOrder(
    'exchange, refund, store credit and shop now',
    [
      [50, { product_id: 1 }],
    ],
    {
      'enabled': {
        'exchange': 'yes',
        'refund': 'yes',
        'gift': 'yes',
        'storefront': 'yes',
        'on_store_api': 'no'
      },
      cart: []
    }
  ),
  createOrder(
    'exchange and shop now',
    [
      [50, { product_id: 1 }],
    ],
    {
      'enabled': {
        'exchange': 'yes',
        'refund': 'no',
        'gift': 'no',
        'storefront': 'no',
        'on_store_api': 'yes'
      },
      cart: []
    }
  ),
  createOrder(
    'multicurrency',
    [
      [51, { product_id: 1 }],
    ],
    {
      'original_presentment_currency': 'GBP',
      'original_presentment_exchange_rate': 1.26,
      'multi_currency': true
    }
  ),
  createOrder(
    'store locator',
    [
      [52, { product_id: 1 }],
    ],
    {
      'stores': locations
    }
  ),
  createOrder(
    'with reimbursements',
    [
      [53, { product_id: 1 }],
    ],
    {
      'return_policy': merge(
        {},
        returnPolicy,
        {
          reimbursement: {
            created_at: '2021-06-14 13:35:16',
            duties_active: true,
            duties_refunds_enabled: true,
            duties_store_credit_enabled: true,
            duties_window: 30,
            duties_window_active: true,
            id: 72,
            return_policy_id: 73,
            shipping_active: true,
            shipping_refunds_enabled: true,
            shipping_store_credit_enabled: true,
            shipping_window: 30,
            shipping_window_active: true,
            updated_at: '2021-06-14 13:35:16'
          }
        }
      )
    }
  ),
  createOrder(
    'store credit reimbursements only',
    [
      [53, { product_id: 1 }],
    ],
    {
      'return_policy': merge(
        {},
        returnPolicy,
        {
          reimbursement: {
            created_at: '2021-06-14 13:35:16',
            duties_active: true,
            duties_refunds_enabled: false,
            duties_store_credit_enabled: true,
            duties_window: 30,
            duties_window_active: true,
            id: 72,
            return_policy_id: 73,
            shipping_active: true,
            shipping_refunds_enabled: false,
            shipping_store_credit_enabled: true,
            shipping_window: 30,
            shipping_window_active: true,
            updated_at: '2021-06-14 13:35:16'
          }
        }
      )
    }
  ),
  createOrder(
    'danish krone',
    [
      [54, { product_id: 1 }],
    ],
    {
      currency: 'DKK'
    }
  ),
  createOrder(
    'japanese yen',
    [
      [55, { product_id: 1 }],
    ],
    {
      currency: 'JPY'
    }
  ),
  createOrder(
    'brazilian real',
    [
      [56, { product_id: 1 }],
    ],
    {
      currency: 'BRL'
    }
  ),
  createOrder(
    'failed line item requests',
    [
      [57, { product_id: 1 }],
      [58, { product_id: 2, fail: true }],
      [59, { product_id: 3, fail: true }],
      [60, { product_id: 4 }],
      [61, { product_id: 5 }],
      [62, { product_id: 6 }],
    ],
  ),
  createOrder(
    'item returned for credit',
    [
      [
        63,
        {
          product_id: 1,
          returnType: 'credit',
          resolution: 'return',
          reason: {
            id: 861
          }
        }
      ],
    ]
  ),
  createOrder(
    'item returned for exchange',
    [
      [
        64,
        {
          product_id: 1,
          returnType: 'exchange',
          resolution: 'exchange',
          reason: {
            id: 861
          }
        }
      ],
    ]
  ),
  createOrder(
    'on store enabled',
    [
      [65, {
        product_id: 1,
        allowed: {
          shopNow: true,
        }
      }],
    ],
    {
      'enabled': {
        'exchange': 'yes',
        'refund': 'yes',
        'gift': 'yes',
        'storefront': 'yes',
        'on_store_api': 'yes'
      }
    }
  ),
  createOrder(
    'loop pos return eligible',
    [
      [66, { product_id: 1 }],
    ],
    {
      // @todo: remove these manual ids an use the mirage assigned ones
      id: 1000,
    }
  ),
  createOrder(
    'happy returns eligible',
    [
      [66, { product_id: 1 }],
    ],
    {
      // @todo: remove these manual ids an use the mirage assigned ones
      id: 1100,
    }
  ),
  createOrder(
    'loop pos and happy returns eligible',
    [
      [66, { product_id: 1 }],
    ],
    {
      // @todo: remove these manual ids an use the mirage assigned ones
      id: 1200,
    }
  ),
  createOrder(
    'olive pickup eligible',
    [
      [67, { product_id: 1 }],
    ],
    {
      // @todo: remove these manual ids an use the mirage assigned ones
      id: 1300,
    }
  ),
  createOrder(
    'happy returns all kept',
    [
      [66, { product_id: 1 }],
    ],
    {
      // @todo: remove these manual ids an use the mirage assigned ones
      id: 1400,
      totals: { shippable: { outcome: 'keep' } }
    }
  ),
  createOrder(
    'veho pickup eligible',
    [
      [68, { product_id: 1 }],
    ],
    {
      // @todo: remove these manual ids an use the mirage assigned ones
      id: 1500,
    }
  ),
  createOrder(
    'rebound eligible',
    [
      [69, { product_id: 1 }],
    ],
    {
      // @todo: remove these manual ids an use the mirage assigned ones
      id: 1600,
    }
  ),
  createOrder(
    'seko usps eligible',
    [
      [69, { product_id: 1 }],
    ],
    {
      // @todo: remove these manual ids an use the mirage assigned ones
      id: 1700,
    }
  ),
  createOrder(
    'delayed eligibility',
    [
      [70, { product_id: 1 }],
    ],
    {
      // @todo: remove these manual ids an use the mirage assigned ones
      id: 1800,
    }
  ),
  {
    'name': 'no shipping address',
    'errors': ['errorCode9'],
    'countries': {
      'US': 'United States',
      'MX': 'Mexico',
      'CA': 'Canada',
      'SE': 'Sweden'
    }
  },
];

export const findOrderByName = (name) => orders.find(o => o.name === name);
