<template>
  <div class="header">
    <div class="back-to-shop-wrapper">
      <back-to-shop
        v-if="!isExperimentBEligible"
        class="back-to-shop-small"
      />
    </div>
    <div class="header-wrapper">
      <component
        :is="component"
        v-if="!$theme.branding.logo || logoError"
        class="header-heading"
        :to="{ name: 'lookup' }"
      >
        <base-text
          aria-hidden="true"
          tag="span"
          type="display-xlarge"
        >
          {{ $shop.name }}
        </base-text>
        <visually-hidden v-if="!isLookup">
          <render-content>
            {{ $content.moduleHeader.accessibleLinkText }}
          </render-content>
        </visually-hidden>
      </component>
      <component
        :is="component"
        v-else
        class="header-img"
        :to="{ name: 'lookup' }"
        :class="logoType"
        :style="ifPortrait()"
      >
        <img
          aria-hidden="true"
          :src="$theme.branding.logo"
          :alt="$shop.name"
          @error="handleLogoError"
          @load="checkRatio($event)"
        />
        <visually-hidden v-if="!isLookup">
          <render-content>
            {{ $content.moduleHeader.accessibleLinkText }}
          </render-content>
        </visually-hidden>
      </component>
      <button
        v-if="cart?.length > 0 && isExperimentBEligible"
        class="cart-button legacy-button"
        data-testid="cart-button"
        @click="$emit('toggleCart', true)"
      >
        <svg
          class="cart-icon"
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
        ><path
          d="M14 4a2 2 0 1 0-4 0h4zm2 0h3a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3a4 4 0 1 1 8 0zM6 6v12h12V6H6z"
          fill="currentColor"
          fill-rule="nonzero"
        /></svg>
        {{ cart.length }} <span class="cart-text">item{{ cart.length !== 1 ? 's' : '' }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { BaseText, VisuallyHidden } from '@loophq/design-system';
import BackToShop from '@/components/app/BackToShop';
import { experiments } from '@/js/constants/experiments';
import LoopSidecart from '../../views/Shop/Sidecart/LoopSidecart';
import defaults from '../../views/Shop/Sidecart/defaults';
import { views } from '../../js/constants/views';

export default {
  components: {
    BaseText,
    VisuallyHidden,
    BackToShop,
    LoopSidecart
  },
  props: {
    cart: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  emits: ['toggleCart'],
  data() {
    return {
      logoType: 'landscape',
      logoWidth: null,
      logoError: false,
      showCart: false,
      loading: false
    };
  },
  computed: {
    isLookup() {
      return ['lookup', 'gift'].includes(this.$route.name);
    },
    component() {
      return this.isLookup ? 'div' : 'router-link';
    },
    isExperimentBEligible() {
      return (this.$store.getters.experimentVariation(experiments.RETURNS_PORTAL_FLOW_B_EXPERIMENT) === experiments.FLOW_B);
    },
    totals() {
      return this.$store.state.totals.totals;
    },
    theme() {
      const theme = this.$theme;

      return {
        theme: {
          ...defaults.theme,
          cornerRadius: theme.globals.sharpness === 'round' ? '3px' : '0',
          primaryColor: theme.branding.primaryColor || null,
          headerFont: theme.heading.font || null,
          copyFont: theme.body.font || null
        },
        text: defaults
      };
    },
    returnsList() {
      return views.RETURNS_LIST;
    }
  },
  methods: {
    handleLogoError() {
      this.logoError = true;
    },
    checkRatio: function (event) {
      if (event && event.target && event.target.height > event.target.width) {
        this.logoType = 'portrait';
      }
    },
    ifPortrait: function () {
      if (this.logoWidth != null) {
        return { width: this.logoWidth };
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.header-heading {
  text-decoration: none;
  color: var(--theme-text-color);
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 0;
  flex-direction: column;
  margin-bottom: var(--spacing-500);

  &-img {
    display: block;
    margin: auto;
    z-index: 10;
    width: 380px;

    img {
      width: 100%;
    }
  }
}

.back-to-shop-small {
  display: none;
  margin-bottom: var(--spacing-500);
  flex-grow: 4;
}

.back-to-shop-wrapper {
  display: block;
  position: relative;
  width: 100%;
}

.cart-button {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  border-radius: var(--theme-corners);
  max-width: 115px;
  position: absolute;
  right: 10rem;
  top: 3rem;
}

.cart-icon {
  margin-right: 0.4rem;
}

.cart-text {
  margin-left: 0.25em;
}

@media screen and (max-width: $break-small) {
  .back-to-shop-small {
    display: block;
  }

  .header {
    padding-top: 0.5rem;
    gap: 0.5rem;

    &-img {
      &.landscape {
        display: block !important;
        width: calc(100% - 2rem);
        height: auto !important;

        img {
          width: 100%;
          height: auto !important;
        }
      }
    }
  }

  .cart-button {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-radius: var(--theme-corners);
    position: absolute;
    right: 0.5rem;
    max-width: 60px;
    top: 2rem;
  }

  .cart-icon {
    margin-right: 0.5rem;
    width: 21px;
    height: 21px;
  }

  .cart-text {
    display: none;
  }
}
</style>
