import axios from 'axios';
import store from '@/store';
import env from '@/env';
import { featureFlags } from '../constants/featureFlags';

const defaultPageSize = 18;

export const getCollectionProducts = function (id, params = { per_page: defaultPageSize }) {
  const shopId = store.getters.shop.id;
  const currency = store.getters.order.currency;
  return axios.post(`api/v1/${shopId}/collections/${id}/products?currency=${currency}`, params)
    .then((res) => {
      return res.data;
    });
};

export const getProductVariants = function(product_id) {
  const shopId = store.getters.shop.id;
  const currency = store.getters.order.currency;
  const presentmentCurrency = store.getters.order.presentment_currency ?? currency;
  const returnPolicyId = store.getters.order.return_policy.id;
  const countryCode = store.getters.order.address.country_code ?? '';

  const url = store.getters.hasFeature(featureFlags.SHOP_NOW_PRICES_CONVERSION) ?
    `api/v1/${shopId}/product/${product_id}/variants?currency=${presentmentCurrency}&returnPolicyId=${returnPolicyId}&countryCode=${countryCode}` :
    `api/v1/${shopId}/product/${product_id}/variants?currency=${currency}&returnPolicyId=${returnPolicyId}`;

  return axios.get(url)
    .then((res) => {
      return res.data;
    });
};

export const getInitializationData = async function() {
  //either set url for dev or grab local window url for stage/prod
  let apiURL = env('VITE_API_URL') !== undefined ? env('VITE_API_URL') : window.location.origin;

  if (env('NODE_ENV') === 'development'
		&& window.location.host.toLowerCase().startsWith('integrationsrus')
		&& env('VITE_INTEGRATIONSRUS_API_URL') !== undefined) {

    apiURL = env('VITE_INTEGRATIONSRUS_API_URL');
  }

  if (process.env.NODE_ENV === 'development'
		&& window.location.host.toLowerCase().startsWith('test')
		&& process.env.VITE_TESTSHOP_API_URL !== undefined) {

    apiURL = process.env.VITE_TESTSHOP_API_URL;
  }

  let axiosConfig = { url: '/api/v1/init', baseURL: apiURL };
  if (import.meta.env.MODE === 'development') {
    axiosConfig.headers = { 'referer-dev': window.location.origin };
  }

  const response = await axios(axiosConfig);
  const { customizations, allowed_languages, ...data } = response.data;
  // Sometimes customizations doesn't exist, and we need this to not blow up
  const { content, style, settings } = (customizations || {});

  return {
    ...data,
    supportedLanguages: allowed_languages,
    customizations: { content, settings, theme: style }
  };
};

export const getTrackingInitializationData = async function(shopProviderDomain) {
  const apiURL = env('VITE_API_URL') || window.location.origin;
  const domainForCustomizations = process.env.NODE_ENV === 'development' ? env('VITE_LOCAL_SETUP_PROVIDER_DOMAIN') : shopProviderDomain;

  const response = await axios.get(`${apiURL}/api/v1/init-shop/${domainForCustomizations}`);

  const { customizations, allowed_languages, ...data } = response.data;
  // Sometimes customizations doesn't exist, and we need this to not blow up
  const { content, style, settings } = (customizations || {});

  return {
    ...data,
    supportedLanguages: allowed_languages,
    customizations: { content, settings, theme: style }
  };
};

export default {
  getCollectionProducts,
  getProductVariants,
  getInitializationData,
  getTrackingInitializationData
};
