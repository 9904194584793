import { getProductVariants } from '@/js/controllers/shop';
import { getRecommendationsForProduct } from '@/js/controllers/shopnow';
import { resolveOrReject } from './helpers';
import useStoreMethods from '@/js/mixins/store';
import { featureFlags } from '@/js/constants/featureFlags';
const { getStore, setStore } = useStoreMethods();

const getCurrentTimestamp = () => {
  return Math.floor(Date.now() / 1000);
};

export default {
  namespaced: true,
  state: {
    items: {}
  },
  mutations: {
    set(state, value) {
      state.items = {
        ...state.items,
        [value.id]: {
          ...value,
          timestamp: getCurrentTimestamp()
        }
      };
      setStore('products', state);
    },
    setState(state, data) {
      Object.entries(data).forEach(([key, value]) => {
        state[key] = value;
      });
    }
  },
  actions: {
    get({ state, commit }, product) {
      const { id } = product;
      const refresh = product.needsRefreshed ?? false;

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        // We already have this product, or have requested it
        const existing = state.items[id];
        const hasCachedData = existing && !existing.failed;
        const cacheLengthInSeconds = 300;
        const needsRefreshed = hasCachedData &&
                    (
                        existing?.timestamp + cacheLengthInSeconds < getCurrentTimestamp() ||
                        // This will take care of refreshing any products cached before
                        // we introduced the cache refresh
                        !existing?.timestamp
                    );

        if (hasCachedData && !needsRefreshed && !refresh) {
          // Update this product with any additional data we now have
          commit('set', { ...state.items[id], ...product });

          // This product is still loading but we don't have a promise for it
          // so we're just polling for it to finish
          if (existing.loading) {
            const interval = setInterval(() => {
              // Finished loading, bail out and send the data
              if (!state.items[id].loading) {
                clearInterval(interval);
                resolveOrReject(resolve, reject, state.items[id]);
              }
            }, 100);
          } else {
            resolveOrReject(resolve, reject, existing);
          }
        } else {
          // Product does not exist or needs refreshed, create it and then fetch the variants
          commit('set', { ...product, loading: true });

          try {
            const variants = await getProductVariants(id);
            const updatedProduct = { ...product, loading: false, variants };
            commit('set', updatedProduct);
            resolve(updatedProduct);
          } catch ({ response, message }) {
            console.error(`Error requesting variants for ${id}: ${message}`);
            if (response) {
              console.error(response);
            }
            const updatedProduct = { ...product, loading: false, failed: true };
            commit('set', updatedProduct);
            reject(updatedProduct);
          }
        }
      });
    },
    hydrate({ commit }) {
      const saved = getStore('products');

      if (saved) {
        commit('setState', saved);
      }
    },
    async getRecommendations({ state, commit, rootGetters }, product) {
      const { id } = product;
      if (
        rootGetters.hasFeature(featureFlags.PRODUCT_RECOMMENDATIONS) &&
        rootGetters.settings.recommendationsEnabled &&
        !('recommendations' in state.items[id])
      ) {
        const recommendations = await getRecommendationsForProduct(id);
        if (!recommendations.errors) {
          const updatedProduct = {
            ...state.items[id],
            recommendations
          };

          commit('set', updatedProduct);
        }
      }
    }
  }
};
