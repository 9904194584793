<template>
  <div
    class="return-method-container"
  >
    <base-text
      type="body-2"
      class="return-method-legal"
    >
      You can drop off at any Happy Returns® location. By selecting you are agreeing to Happy Returns’
      <a
        v-track="'happy returns privacy policy clicked'"
        target="_blank"
        class="legal-link"
        href="https://www.privacypolicy.happyreturns.com"
      >
        privacy policy
      </a>
    </base-text>

    <alert-banner
      v-if="showRemovedLocationsAlert"
      class="alert"
      heading="Happy Returns is moving from FedEx Office to The UPS Store"
      description="Your nearest Return Bar locations may have changed and are listed below."
      type="warning"
      :inline="true"
    />

    <happy-returns-locations 
      v-if="showThreeLocations"
      :locations="returnMethod.closest"
    />
    <div
      v-else
      class="distance"
    >
      <base-text
        v-if="distance?.distance && distance?.unit"
        class="heading"
        type="body-2"
      >
        <render-content :data="distance">
          {{ $content.moduleReturnMethodSelectorHappyReturns.happyReturnsBarDistance }}
        </render-content>
      </base-text>
      <base-button
        v-track="'happy returns get directions clicked'"
        type="link"
        class="link"
        :to="googleMapsLink"
        target="_blank"
      >
        <base-text 
          class="icon-label"
          type="body-2"
        >
          {{ returnMethod.address.name }}
          <base-icon
            class="link-icon"
            name="external-link"
          />
        </base-text>
      </base-button>
    </div>

    <base-button
      v-track="'happy returns view all locations clicked'"
      class="all-locations-btn"
      size="small"
      type="secondary"
      target="_blank"
      :to="locationsLink"
      @click.stop
    >
      <span
        class="icon-label"
      >
        {{ $content.moduleReturnMethodSelectorHappyReturns.viewAllLocationsBtn }}
        <base-icon
          class="external-icon"
          name="external-link"
        />
      </span>
    </base-button>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseText,
  BaseIcon,
  AlertBanner,
} from '@loophq/design-system';
import { featureFlags } from '@/js/constants/featureFlags';
import HappyReturnsLocations from './HappyReturnsLocations';

export default {
  components: {
    BaseButton,
    BaseText,
    BaseIcon,
    AlertBanner,
    HappyReturnsLocations,
  },
  props: {
    returnMethod: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    showRemovedLocationsAlert() {
      return this.$store.getters.hasFeature(featureFlags.HAPPY_RETURNS_REMOVED_LOCATIONS_ALERT_BANNER);
    },
    showThreeLocations() {
      return this.$store.getters.hasFeature(featureFlags.SHOW_THREE_HAPPY_RETURNS_LOCATIONS) && this.returnMethod?.closest?.length > 0;
    },
    locationsLink() {
      return `https://locations.happyreturns.com/?has_qr_code=true&address=${this.returnMethod.address?.zip}`;
    },
    googleMapsLink() {
      return `https://www.google.com/maps/dir/?api=1&destination=${this.returnMethod.address.latitude},${this.returnMethod.address.longitude}`;
    },
    address() {
      // eslint-disable-next-line
      const { name, ...rest } = this.returnMethod.address;
      return { ...rest };
    },
    displayFields() {
      return this.returnMethod.display.filter(item => item.value);
    },
    distance() {
      return {
        distance: this.returnMethod?.distance?.value?.toFixed(1),
        unit: this.returnMethod?.distance?.unit === 'm' ? 'miles' : this.returnMethod?.distance?.unit
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.all-locations-btn {
  color: var(--grey-800) !important;
  margin-top: 0;
}

.alert {
  background: #f1ebd0 !important;
  border: none;
  margin-bottom: var(--spacing-400);

  :deep(.base-text) {
    color: var(--heading-color);
    font-size: 14px;
  }
}

.distance {
  margin-bottom: 12px;
}

.heading {
  display: inline-block;
  color: var(--grey-800);
  font-weight: 500;
  padding-right: var(--spacing-200);
}

.link {
  color: var(--grey-800) !important;
  font-weight: normal;
  display: inline-block !important;
}

.location-info {
  margin: var(--spacing-300) 0;
}

.show-more {
  margin: var(--spacing-400) 0 0;
  display: flex !important;
}

.show-more-label {
  display: flex;
  color: var(--primary-color);
}

.return-method-container {
  padding: var(--spacing-400);
}

.external-icon {
  height: 16px;
  margin-left: var(--spacing-200);
}

.icon-label {
  display: flex;
  align-items: center;
}

.link .icon-label {
  text-decoration: underline;
}

.link-icon {
  height: 12px;
}

.return-method-legal {
  color: var(--grey-800);
  margin-bottom: 1.25rem;
}

.legal-link {
  color: var(--grey-800);
}

@media screen and (max-width: $break-small) {
  .distance {
    display: block;
  }
}
</style>
