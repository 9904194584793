<template>
  <div class="shop-now-recommendations">
    <base-text
      tag="h2"
      type="display-medium"
    >
      {{ $content.pageShopNow.productViewRecommendationsHeading }}
    </base-text>
    <div class="shop-now-recommendations__carousel">
      <div
        v-if="showPrevButton"
        class="shop-now-recommendations__button-wrap --prev"
      >
        <button
          class="shop-now-recommendations__button"
          type="button"
          @click.stop="onScrollPrev"
        >
          <base-icon name="chevron-left" />
        </button>
      </div>
      <div
        ref="products"
        class="shop-now-recommendations__products"
        :class="{'--is-scrolled': isScrolled}"
        @scroll="onScroll"
      >
        <router-link
          v-for="product in recommendations"
          :key="product.id"
          class="shop-now-recommendations__product"
          :to="buildLinkToProduct(product)"
        >
          <img
            class="shop-now-recommendations__image"
            :src="product.image"
          />
          <base-text
            class="shop-now-recommendations__product-title"
            tag="h4"
            type="body-2"
          >
            {{ product.title }}
          </base-text>
          <base-text
            class="shop-now-recommendations__product-price"
            tag="p"
            type="body-2"
          >
            {{ formatCurrency(product.price, $settings.currency) }}
          </base-text>
        </router-link>
      </div>
      <div
        v-if="showNextButton"
        class="shop-now-recommendations__button-wrap --next"
      >
        <button
          class="shop-now-recommendations__button"
          type="button"
          @click.stop="onScrollNext"
        >
          <base-icon name="chevron-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseIcon,
  BaseText
} from '@loophq/design-system';
import { formatCurrency } from '@/js/helpers/formatCurrency';

export default {
  name: 'ShopNowRecommendations',
  components: {
    BaseIcon,
    BaseText,
  },
  props: {
    recommendations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      formatCurrency,
      isScrolled: false,
      showNextButton: false,
      showPrevButton: false,
    };
  },
  mounted() {
    if (this.$refs.products.clientWidth < this.$refs.products.scrollWidth) {
      this.showNextButton = true;
    }
  },
  methods: {
    onScrollNext() {
      const children = this.$refs.products.children;
      // find the next hidden item and scroll to it as first item in new page.
      let scrollToIndex = this.getElementsViewed() + this.getPerPage();
      scrollToIndex = scrollToIndex >= children.length ? children.length : scrollToIndex;

      this.$refs.products.scrollTo({
        left: children[scrollToIndex - 1].offsetLeft
      });
    },
    onScrollPrev() {
      // find the next hidden item and scroll to it.
      let scrollToIndex = this.getElementsViewed() - this.getPerPage();
      scrollToIndex = scrollToIndex < 1 ? 1 : scrollToIndex;

      this.$refs.products.scrollTo({
        left: this.$refs.products.children[scrollToIndex - 1].offsetLeft
      });
    },
    getChildItemWidth() {
      // calculate width of the children product elements accounting for the grid gap. the gap
      // defaults to 16px. add half of this gap width to determine each item's reserved space.
      const elementWidth = this.$refs.products.firstElementChild.clientWidth;
      const gapWidth = parseInt(window.getComputedStyle(this.$refs.products)?.gap ?? 16, 10);
      return elementWidth + (gapWidth / 2);
    },
    onScroll() {
      const scrollPadLeft = this.getScrollPadLeft();
      const maxScrollWidth = this.$refs.products.scrollWidth - this.$refs.products.clientWidth;
      this.showNextButton = this.$refs.products.scrollLeft < maxScrollWidth;
      this.showPrevButton = this.$refs.products.scrollLeft > 0;
      this.isScrolled = this.$refs.products.scrollLeft > scrollPadLeft;
    },
    getScrollPadLeft() {
      return parseInt(window.getComputedStyle(this.$refs.products)?.scrollPaddingLeft ?? 40, 10);
    },
    getElementsViewed() {
      // estimate how many elements have been viewed. always count first element as viewed
      // and account for scroll padding.
      const padLeft = this.getScrollPadLeft();
      const childWidth = this.getChildItemWidth();
      return Math.ceil((this.$refs.products.scrollLeft + padLeft) / childWidth) || 1;
    },
    getPerPage() {
      // estimate the number of items per carousel "page" based on the width of the carousel.
      // only counts fully visible items (does not count items partially hidden in overflow).
      return Math.floor(this.$refs.products.clientWidth / this.getChildItemWidth());
    },
    buildLinkToProduct(product) {
      // todo this assumes an array of collection objects coming back (still awaiting final data structure)
      return {
        path: `/shop/collection/${product.collections[0]?.id}/product/${product.id}/`,
        query: this.$route.query
      };
    },
  }
};
</script>

<style lang="scss" scoped>
$block: '.shop-now-recommendations';
$img-height: 132px;
$scroll-btn-z-index: 2;

#{$block} {
  padding: var(--spacing-400);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);

  &__carousel {
    position: relative;
    padding-right: 40px;
  }

  &__button-wrap {
    position: absolute;
    top: 0;
    background: #fff;
    height: 100%;

    &.--next {
      right: 0;
      padding-left: 8px;
    }

    &.--prev {
      left: 0;
      padding-right: 8px;
      z-index: $scroll-btn-z-index;
    }
  }

  &__button {
    height: $img-height;
    background-color: #fff;
    width: 32px;
    border: 1px solid var(--grey-200);
    border-radius: calc(var(--corners) / 2);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color var(--transition-300);
    box-sizing: border-box;

    &:hover {
      background-color: var(--grey-100);
    }
  }

  &__products {
    display: grid;
    gap: var(--spacing-300);
    grid-template-columns: repeat(auto-fill, 110px);
    grid-auto-flow: column;
    grid-auto-columns: 110px;
    scroll-behavior: smooth;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scroll-padding-left: 40px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.--is-scrolled {
      #{$block}__product:first-child {
        // when scrolling begins, first child item should sit below the previous button.
        // this avoids funky transitions when scrolling back to beginning of list.
        z-index: $scroll-btn-z-index - 1;
      }
    }
  }

  &__product {
    text-align: left;
    text-decoration: none;
    scroll-snap-align: start;

    &:first-child {
      z-index: $scroll-btn-z-index + 1;
    }
  }

  &__image {
    height: $img-height;
    max-width: 100%;
    width: auto;
    object-fit: cover;
    border-radius: calc(var(--corners) / 2);
  }

  &__product-title {
    font-weight: 600;
  }

  &__product-price {
    color: var(--grey-700);
  }
}
</style>
